import { IPersonaProps, ValidationState } from "office-ui-fabric-react";
// import * as EmailValidator from "email-validator";
import { TeamMemberNormalized } from "../models/projectTeam/TeamViewResponse";
import { WorkflowActionContact } from "../models/Contact";
import { Keyword } from "../models/Keyword";
import { DetailedKeyword } from "../models/ProjectKeywordsResponse";

export class FormValidationHelpers {
    isValidEmail(input: string, isValidGroup = false): boolean {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const isOkEmail = pattern.test(input);
        return isOkEmail || isValidGroup;
    }

    validateEmailInput(input: string, isValidGroup = false, isSameToAndCc = false): ValidationState {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        // from fluent ui: https://developer.microsoft.com/en-us/fluentui#/controls/web/peoplepicker
        if ((pattern.test(input) || isValidGroup) && !isSameToAndCc) {
            return ValidationState.valid;
        } else if (input.length > 1 && !isValidGroup && !isSameToAndCc) {
            return ValidationState.warning;
        } else {
            return ValidationState.invalid;
        }
    }

    areAssigneesValid(assignees: IPersonaProps[], required: boolean): boolean {
        if (!assignees.length) {
            return !required;
        }

        const invalidAssignees = assignees.filter((persona) => {
            if (!persona.text || !!(persona as any).data?.nrn) {
                return false;
            }
            return this.validateEmailInput(persona.text) !== ValidationState.valid;
        });

        return !invalidAssignees.length;
    }

    getPersonaPropsFromTeamMembers(teamMembers: TeamMemberNormalized[]): IPersonaProps[] {
        return teamMembers.map((member) => {
            return {
                text: member.displayName,
                secondaryText: member.email,
                showSecondaryText: !!member.displayName,
                data: member,
            };
        });
    }

    mapPersonaPropsToContact(items: IPersonaProps[]): WorkflowActionContact[] {
        return items
            .filter((item) => !!item.text)
            .map((item) => {
                let email = item?.text?.trim() ?? "";
                const Data = (item as any).data;
                if (!!Data) {
                    const nrn = Data?.nrn;
                    if (!!nrn) {
                        return { nrn };
                    }
                    email = Data?.email;
                    if (!!email) {
                        return { email };
                    }
                }

                return { email };
            });
    }

    mapPersonaPropsToKeywords(items: IPersonaProps[]): Keyword[] {
        return items
            .filter((item) => !!item.text)
            .map((item) => {
                const data = (item as any).data;
                if (data) {
                    return data as Keyword;
                }
                return { name: (item.text as string).trim(), type: "generic" };
            });
    }

    mapDetailedKeywordToKeyword(item: DetailedKeyword): Keyword {
        return {
            name: item.name,
            type: item.type,
        };
    }

    isSpecSectionValid(input: string): ValidationState {
        if (input.match(/^( *\d){6}/g)) {
            return ValidationState.valid;
        }

        return ValidationState.invalid;
    }
}
