import "./FromAndToComponent.less";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { MailboxItem, OfficeWrapper } from "../../../services/OfficeWrapper";
import { IPersonaProps, ITag, MessageBarType } from "office-ui-fabric-react";
import { FormValidationHelpers } from "../../../helpers/FormValidationHelpers";
import EmailAddressDetails = Office.EmailAddressDetails;
import AssigneeComponent from "../assignee/AssigneeComponent";
import { Logger } from "../../../services/Logger";
import { IProjectsService } from "../../../services/NewformaApi/IProjectsService";
import { TeamMemberNormalized } from "../../../models/projectTeam/TeamViewResponse";
import LinkComponent from "../linkComponent/LinkComponent";
import { EmailRecipients } from "../../../models/shared/FileTransferEmailFieldsDetails";
import * as _ from "lodash";

export interface FromAndToComponentProps extends LocalizeContextProps {
    key?: string;
    className?: string;
    isFromRequired: boolean;
    isToRequired: boolean;
    isCcRequired?: boolean;
    includeCc: boolean;
    officeWrapper: OfficeWrapper;
    onFromChange: (people: IPersonaProps[]) => void;
    onToChange: (people: IPersonaProps[]) => void;
    onCcChange?: (people: IPersonaProps[]) => void;
    disabled: boolean;
    formValidationHelpers: FormValidationHelpers;
    mailboxItem: MailboxItem | null;
    project: ITag | null;
    logger: Logger;
    projectsService: IProjectsService;
    onError: (message: string | null, type: MessageBarType) => void;
    shouldHideFrom: boolean;
    shouldClearEmails?: boolean;
    isTransferFailed?: boolean;
    EmailRecipients?: EmailRecipients;
    isFileTransferComponent?: boolean;
    isForwardInProgress?: boolean;
    onEmailCheck?: (value: boolean) => void;
    disableOnError?: boolean;
}

export interface FromAndToComponentState {
    from: IPersonaProps[];
    to: IPersonaProps[];
    cc: IPersonaProps[];
    showCcField: boolean;
    isLoadingTeamMembers: boolean;
    sameToAndCc: boolean;
    sameToAndCcEmail: String[];
    ccKey?: number;
emailGroups: IPersonaProps[];
}

class FromAndToComponent extends React.Component<FromAndToComponentProps, FromAndToComponentState> {
    constructor(props: FromAndToComponentProps, context: FromAndToComponentState) {
        super(props, context);

        this.state = {
            from: [],
            to: [],
            cc: [],
            showCcField: false,
            isLoadingTeamMembers: false,
            sameToAndCc: false,
            sameToAndCcEmail: [],
            ccKey: 0,
emailGroups: [],
        };
    }
    errorMessage: string = "";

    async componentDidMount() {
        const projectNrn = this.props.project?.key;
        if (!projectNrn) {
        this.populateFields();
            return;
        }
        await this.loadGroupTeamMember(projectNrn);
    }

    async componentDidUpdate(
        prevProps: Readonly<FromAndToComponentProps>,
        prevState: Readonly<FromAndToComponentState>,
        snapshot?: any
    ): Promise<void> {
        console.log("FromAndToComponent Updated");
        if (prevProps.mailboxItem !== this.props.mailboxItem) {
            this.populateFields();
            return;
        }

        if (this.props.project?.key && prevProps.project?.key !== this.props.project.key) {
            await this.loadGroupTeamMember(this.props.project.key);
            await this.lookupContacts(this.props.project.key);
            return;
        }
        this.addOrRemoveExpandableClass();
    }

    log(msg: string) {
        console.log(`FromTo [${this.props.key}] ${msg}`);
    }

    private populateFields(): void {
        if (this.props.shouldClearEmails) {
            this.setState({
                from: [],
                to: [],
                cc: [],
            });
            return;
        }
        const from = this.props.officeWrapper.getSenderEmailAddress();
        const to = this.props.officeWrapper.getCurrentEmailToRecipients();
        const cc = this.props.officeWrapper.getCurrentEmailCcRecipients();

        const fromAsPersonaProps =
            this.props.isForwardInProgress && this.props.EmailRecipients && this.props.EmailRecipients.from?.length
                ? this.props.EmailRecipients?.from
                : from
                ? [{ text: from }]
                : [];
        const toAsPersonaProps = this.props.isForwardInProgress
            ? this.getFieldForForwardSubmittal("to")
            : this.props.isTransferFailed && this.props.EmailRecipients && this.props.EmailRecipients.to?.length
            ? this.props.EmailRecipients?.to
            : this.removeNewformaAutoFileEmail(to);

        const ccAsPersonaProps = this.props.isForwardInProgress
            ? this.getFieldForForwardSubmittal("cc")
            : this.props.isTransferFailed && this.props.EmailRecipients && this.props.EmailRecipients.cc?.length
            ? this.props.EmailRecipients?.cc
            : this.removeNewformaAutoFileEmail(cc);

        this.props.onFromChange(
            this.props.isFileTransferComponent ? this.removeNewformaAutoFileEmail(to) : fromAsPersonaProps
        );
        this.props.onToChange(
            this.props.isFileTransferComponent && !this.props.isTransferFailed ? fromAsPersonaProps : toAsPersonaProps
        );
        if (this.props.onCcChange) {
            this.props.onCcChange(ccAsPersonaProps);
        }
        const fromValues = this.props.isFileTransferComponent
            ? this.removeNewformaAutoFileEmail(to)
            : fromAsPersonaProps;
        const toValues =
            this.props.isFileTransferComponent && !this.props.isTransferFailed ? fromAsPersonaProps : toAsPersonaProps;

        this.checkToAndCcEmails(toValues, ccAsPersonaProps);

        this.setState({
            from: fromValues,
            to: toValues,
            cc: ccAsPersonaProps,
            showCcField: !!(ccAsPersonaProps.length && this.props.includeCc),
        });
    }

    private async loadGroupTeamMember(projectNrn: string): Promise<void> { 
        try {
            const teamMembers = await this.props.projectsService.getAllTeamMembersNormalized(projectNrn, "");
            if (!Array.isArray(teamMembers)) {
                console.error("Expected an array of team members, but got:", teamMembers);
                return;
            }
                const newGroups = teamMembers.filter((p) => p.nrn.includes("nrn:teamgroup"));
                const newTeamGroups = this.props.formValidationHelpers.getPersonaPropsFromTeamMembers(newGroups);
    
                this.setState({
                    emailGroups: newTeamGroups,
                });
    
                this.populateFields();
        } catch (error) {
                console.error("Error loading group team members:", error);
        }    
    }
    

    private async lookupContacts(projectNrn: string): Promise<void> {
        try {
            this.setState({ isLoadingTeamMembers: true });
            const allTeamMembers = await this.props.projectsService.getAllTeamMembersNormalized(projectNrn);
            const updatedFrom = this.convertEmailsToNewformaTeamMember(this.state.from, allTeamMembers);
            const updatedTo = this.convertEmailsToNewformaTeamMember(this.state.to, allTeamMembers);
            const updatedCc = this.convertEmailsToNewformaTeamMember(this.state.cc, allTeamMembers);

            this.setState({ from: updatedFrom, to: updatedTo, cc: updatedCc });
        } catch (error) {
            this.props.logger.error("FromAndToComponent failed to load team members", error);
        } finally {
            this.setState({ isLoadingTeamMembers: false });
        }
    }

    private convertEmailsToNewformaTeamMember(
        prepopulatedPersonas: IPersonaProps[],
        allTeamMembers: TeamMemberNormalized[]
    ): IPersonaProps[] {
        return prepopulatedPersonas.map((contact) => {
            if ((contact as any).data) {
                // if data is set then persona already from project team
                return contact;
            }
            const teamMembers = allTeamMembers.filter((member) => member.email === contact.text);
            if (!teamMembers.length || teamMembers.length > 1) {
                return contact;
            }
            const teamMember = teamMembers[0];
            return this.props.formValidationHelpers.getPersonaPropsFromTeamMembers([teamMember])[0];
        });
    }

    private removeNewformaAutoFileEmail(recipients: EmailAddressDetails[]): IPersonaProps[] {
        return recipients
            .map((recipient) => recipient.emailAddress)
            .filter((email) => !email.toLowerCase().endsWith("newforma.email"))
            .map((email) => ({ text: email }));
    }

    private onFromChange(items: IPersonaProps[]): void {
        this.setState({ from: items });
        this.props.onFromChange(items);
    }

    private onToChange(items: IPersonaProps[]): void {
        this.setState({ to: items });
        this.props.onToChange(items);
        this.checkToAndCcEmails(items, this.state.cc);
    }

    private onCcChange(items: IPersonaProps[]): void {
        this.setState({ cc: items });
        if (this.props.onCcChange) {
            this.props.onCcChange(items);
        }
        this.checkToAndCcEmails(this.state.to, items);
    }

    private onShowCc(): void {
        this.setState({ showCcField: true });
    }

    private addOrRemoveExpandableClass(): void {
        const toEmailField = document.querySelector(".newforma-toPicker");
        if (this.state.to.length >= 3) {
            toEmailField?.classList.add("expandable");
            toEmailField?.classList.remove("notExpandable");
        } else {
            toEmailField?.classList.add("notExpandable");
            toEmailField?.classList.remove("expandable");
        }

        const ccEmailField = document.querySelector(".newforma-ccPicker");
        if (this.state.cc.length >= 3) {
            ccEmailField?.classList.add("expandable");
            ccEmailField?.classList.remove("notExpandable");
        } else {
            ccEmailField?.classList.add("notExpandable");
            ccEmailField?.classList.remove("expandable");
        }
    }

    private getFieldForForwardSubmittal(field: "to" | "cc"): IPersonaProps[] {
        return this.props.EmailRecipients && _.size(this.props.EmailRecipients[field])
            ? this.props.EmailRecipients[field] || []
            : [];
    }

    private checkToAndCcEmails(toItems: IPersonaProps[], ccItems: IPersonaProps[]) {
        let toEmailsOnly: String[];
        let ccEmailsOnly: String[];
        let sameToAndCc = false;
        toEmailsOnly = [];
        ccEmailsOnly = [];
        const ToAndCcEmail: String[] = [];
        toItems.map((toEmail) => {
            if (toEmail.secondaryText) {
                toEmailsOnly.push(toEmail.secondaryText);
            } else if (toEmail.text) {
                toEmailsOnly.push(toEmail.text);
            }
        });
        ccItems.map((ccEmail) => {
            if (ccEmail.secondaryText) {
                ccEmailsOnly.push(ccEmail.secondaryText);
            } else if (ccEmail.text) {
                ccEmailsOnly.push(ccEmail.text);
            }
        });
        toEmailsOnly.map((toEmail) => {
            if (sameToAndCc) {
                return;
            }
            sameToAndCc = ccEmailsOnly.includes(toEmail);
        });
        if (!sameToAndCc) {
            ccEmailsOnly.map((ccEmail) => {
                if (sameToAndCc) {
                    return;
                }
                sameToAndCc = toEmailsOnly.includes(ccEmail);
            });
        }

        toEmailsOnly.map((toEmail) => {
            if (ccEmailsOnly.includes(toEmail)) {
                ToAndCcEmail.push(toEmail);
            }
        });

        if (!sameToAndCc) {
            ccEmailsOnly.map((ccEmail) => {
                if (toEmailsOnly.includes(ccEmail)) {
                    if (!ToAndCcEmail.includes(ccEmail)) {
                        ToAndCcEmail.push(ccEmail);
                    }
                }
            });
        }

        this.setState({ sameToAndCc });
        this.setState({ sameToAndCcEmail: ToAndCcEmail });
        if (this.props.onEmailCheck) {
            this.props.onEmailCheck(sameToAndCc);
        }
    }
    private onError(message: string | null, type: MessageBarType): void {
        this.props.onError(message, type);
    }
    private onErrorCc(message: string | null, type: MessageBarType): void {
        // force a redraw?
        this.errorMessage = "an error occurred";
        this.setState({ ccKey: this.state.ccKey ?? 0 + 1 });
        this.props.onError(message, type);
    }

    private renderFromComponent(): JSX.Element {
        return (
            <>
                {!this.props.shouldHideFrom ? (
                    <AssigneeComponent
                        className="newforma-formSpacing newforma-fromPicker"
                        assignees={this.state.from}
                        selectedProject={this.props.project}
                        disabled={this.props.disabled || this.state.isLoadingTeamMembers}
                        currentUserEmail={this.props.officeWrapper.userProfileEmailAddress}
                        onError={!!this.props.disableOnError ? () => null : this.onErrorCc.bind(this)} // {this.props.onError}
                        // onError={this.onError.bind(this)}
                        onAssigneeChanged={this.onFromChange.bind(this)}
                        formValidationHelpers={this.props.formValidationHelpers}
                        logger={this.props.logger}
                        projectsService={this.props.projectsService}
                        label={this.props.translate("SHARED.FROM_AND_TO.FROM_LABEL") as string}
                        required={this.props.isFromRequired}
                        showAssignToMe={this.props.shouldHideFrom}
                        maxAllowed={1}
                        sameToAndCcEmail={this.state.sameToAndCcEmail}
                        panelString={this.props.key}
                        emailGroup={this.state.emailGroups}
                    />
                ) : null}
            </>
        );
    }

    private renderToComponent(): JSX.Element {
        return (
            <AssigneeComponent
                className="newforma-formSpacing newforma-toPicker"
                assignees={this.props.shouldClearEmails ? [] : this.state.to}
                selectedProject={this.props.project}
                disabled={this.props.disabled || this.state.isLoadingTeamMembers}
                currentUserEmail={this.props.officeWrapper.userProfileEmailAddress}
                /* onError={this.onError.bind(this)}*/
                onError={!!this.props.disableOnError ? () => null : this.onErrorCc.bind(this)} // {this.props.onError}
                onAssigneeChanged={this.onToChange.bind(this)}
                formValidationHelpers={this.props.formValidationHelpers}
                logger={this.props.logger}
                projectsService={this.props.projectsService}
                label={this.props.translate("SHARED.FROM_AND_TO.TO_LABEL") as string}
                required={this.props.isToRequired}
                showAssignToMe={false}
                type="to"
                panelString={this.props.key}
                sameToAndCcEmail={this.state.sameToAndCcEmail}
                emailGroup={this.state.emailGroups}
            />
        );
    }
    renderAddCc(): JSX.Element {
        if (!this.props.includeCc) {
            return <></>;
        }
        return (
            <>
                {!this.state.showCcField ? (
                    <LinkComponent
                        className="newforma-addCc"
                        text={this.props.translate("SHARED.FROM_AND_TO.ADD_CC") as string}
                        onClick={this.onShowCc.bind(this)}
                    />
                ) : null}
            </>
        );
    }
    private renderCcField(): JSX.Element {
        return (
            <>
                {this.props.includeCc ? (
                    <div className="newforma-ccContainer" hidden={!this.state.showCcField}>
                        <AssigneeComponent
                            key={this.state.ccKey ?? 0}
                            className="newforma-ccPicker"
                            assignees={this.props.shouldClearEmails ? [] : this.state.cc}
                            selectedProject={this.props.project}
                            disabled={this.props.disabled || this.state.isLoadingTeamMembers}
                            currentUserEmail={this.props.officeWrapper.userProfileEmailAddress}
                            onError={!!this.props.disableOnError ? () => null : this.onErrorCc.bind(this)} // {this.props.onError}
                            onAssigneeChanged={this.onCcChange.bind(this)}
                            formValidationHelpers={this.props.formValidationHelpers}
                            logger={this.props.logger}
                            projectsService={this.props.projectsService}
                            label={this.props.translate("SHARED.FROM_AND_TO.CC_LABEL") as string}
                            required={!!this.props.isCcRequired}
                            showAssignToMe={false}
                            type="cc"
                            sameCcAndTo={this.state.sameToAndCc}
                            panelString={this.props.key}
                            sameToAndCcEmail={this.state.sameToAndCcEmail}
                            errorMessage={this.errorMessage}
                            emailGroup={this.state.emailGroups}
                        />
                    </div>
                ) : null}
                {this.renderAddCc()}
            </>
        );
    }

    render(): JSX.Element {
        return (
            <div className={`${this.props.className} newforma-fromAndToComponent`}>
                {this.renderFromComponent()}
                {this.renderToComponent()}
                {this.renderCcField()}
            </div>
        );
    }
}

export default withLocalize(FromAndToComponent);
