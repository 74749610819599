import { NewformaApiClient } from "./NewformaApiClient";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { Log } from "../Logger";
import { LogSubmittalRequest } from "../../models/workflow/submittal/LogSubmittalRequest";
import { Request } from "aws-sign-web";
import { ApiRequestErrorLevel, ApiRequestErrorWithMessage } from "../../models/ApiRequestErrorWithMessage";
import { LogSubmittalResponse } from "../../models/workflow/submittal/LogSubmittalResponse";
import { LogSubmittalObjectResponse } from "../../models/workflow/submittal/LogSubmittalAndForwardResponse";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import { OutlookApiService } from "../OutlookApiService";
import { FileUploadApiService } from "./FileUploadApiService";
import { AttachmentDetailsMetadata } from "../../models/AttachmentDetailsMetadata";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../AnalyticsManager";
import v4 = require("uuid/v4");
import { SubmittalReviewResponseBodyParams } from "../../models/workflow/submittal/SubmittalWorkflowActions";
import { WorkflowActionResponse } from "../../models/workflow/WorkflowActionResponse";
import { SubmittalDetailsResponse } from "../../models/workflow/submittal/SubmittalDetailsResponse";
import { ProjectKeywordsResponse } from "../../models/ProjectKeywordsResponse";
import { Submittal, SubmittalsResponse } from "../../models/workflow/submittal/SubmittalsResponse";
import { WorkflowActionType } from "../../models/workflow/WorkflowActionType";
import { EmailApiService } from "./EmailApiService";
import { NrnServiceWrapper } from "../NrnServiceWrapper";
import { Keyword } from "../../models/Keyword";
import { PostEmailFilingResponse } from "../../models/PostEmailFilingResponse";
import { AttachmentItem } from "../../models/shared/AttachmentList";
import { mapAttachmentsMetadataToAttachment } from "../../helpers/SendAndFile/AttachmentHelpers";
import { LogForwardSubmittalRequest } from "../../models/workflow/submittal/ForwardSubmittalRequest";
import { LogForwardSubmittalResponse } from "../../models/workflow/submittal/ForwardSubmittalResponse";
import { delay } from "../../helpers/UtilityHelper";
import { LocalStorageKeys as LS } from "../../models/StorageKeys";
import { DateHelper } from "../../helpers/DateHelpers";

const CACHED_SUBMITTALS = LS.cachedSubmittals;
const MAX_SUBMITTALS = LS.maxSubmittalsPageSize;
const CACHE_DISABLED = LS.cacheDisabled;

export enum SubmittalStatus {
    Closed = "closed",
    Forwarded = "forwarded",
    Open = "open",
}

export class SubmittalsApiService {
    private componentName: string = `${this.constructor.name}.`;
    private _urlRoot: string = "";
    private maxRetries = 3;
    private retryDelay = 5000; // 5 seconds
    private lock = false;

    private abortCount: number = 0;
    private _wasCached = false;
    private _cacheServer = "";
    private _submittals: any = [];
    private _expiredSubmittalsMs = 5 * 60 * 1000; // 5 minutes
    private maxSubmittalsPageSize = 400;

    constructor(
        private newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private outlookApiService: OutlookApiService,
        private fileUploadApiService: FileUploadApiService,
        private analyticsManager: AnalyticsManager,
        private emailApiService: EmailApiService,
        private nrnServiceWrapper: NrnServiceWrapper
    ) {
        const cacheSubmittals = localStorage.getItem(CACHED_SUBMITTALS);
        if (cacheSubmittals) {
            try {
                this._wasCached = true;

                this._submittals = JSON.parse(cacheSubmittals);

                const first = this._submittals[0]?.items[0]?.key;
                this._cacheServer = this.getNPCServer(first);
                Log.info(`Cached from server ${this._cacheServer}`);
                // make sure nothing too old is used.
                this.purgeCacheSubmittals();
            } catch {}
        } else {
            // no external cache make sure the memory cache is also empty
            this._submittals = [];
        }
        // load allowed page size if existing.
        const maxPageSize = localStorage.getItem(MAX_SUBMITTALS);
        if (maxPageSize) {
            this.maxSubmittalsPageSize = Number(maxPageSize);
        } else {
            localStorage.setItem(MAX_SUBMITTALS, `${this.maxSubmittalsPageSize}`);
        }
    }

    get AbortCount() {
        return this.abortCount;
    }

    get urlRoot() {
        if (this._urlRoot === "") {
            this._urlRoot = this.newformaApiClient.getHostNameWithProtocol();
        }
        return this._urlRoot;
    }

    getNPCServer(nrn: string): string {
        if (nrn) {
            const parts = nrn.split(":");

            if (parts[0] === "nrn") {
                return parts[5];
            }
        }
        return "";
    }

    getVia(projectNrn: string): Keyword | undefined {
        if (!this.nrnServiceWrapper.isCloudProject(projectNrn)) {
            return { name: "Email", type: "transferMethod" };
        }
        return undefined;
    }

    async logEmailAsSubmittal(
        projectNrn: string,
        messageNrn: string,
        submittal: LogSubmittalRequest,
        attachments: AttachmentItem[],
        fileUploadCallback: (isInProgress: boolean, failedIds: string[]) => void,
        isLastModifiedDateSupported: boolean
    ): Promise<LogSubmittalObjectResponse> {
        Log.info(`${this.componentName}logEmailAsSubmittal`);
        let attachmentsDetails: AttachmentDetailsMetadata[] = [];

        if (attachments.length > 0) {
            attachmentsDetails = await this.outlookApiService.getFileAttachmentDetailsMetadata(
                attachments,
                isLastModifiedDateSupported
            );
            submittal.attachments = mapAttachmentsMetadataToAttachment(attachmentsDetails, isLastModifiedDateSupported);
        }

        let response: LogSubmittalResponse;

        try {
            response = await this.logSubmittal(submittal, projectNrn);
        } catch (error) {
            Log.error(`${this.componentName}Logging a submittal ${JSON.stringify(error)}`);

            const handledErrors: { [index: string]: string } = {
                403: "SUBMITTALS.UNAUTHORIZED_ERROR",
                409: "SUBMITTALS.CREATE_FAILED_CONFLICT",
                500: "SUBMITTALS.UNEXPECTED_ERROR",
            };
            // if the error code doesn't match the above list, or any of its parent objects are not defined, it will map to 500
            const errorCode = (error as any).response?.status || 500;
            const messageToDisplay = handledErrors[errorCode] ?? handledErrors[500];
            if (messageToDisplay) {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.ERROR,
                    messageToDisplay,
                    errorCode
                );
            }

            throw error;
        }

        if (attachments.length > 0) {
            const batchId = v4();
            const result = await this.fileUploadApiService.uploadWorkflowAttachments(
                attachmentsDetails,
                response.uploadFolderNrn,
                fileUploadCallback,
                batchId
            );
            fileUploadCallback(false, result.failedFileIds);
            if (result.failedFileIds.length > 0) {
                throw new ApiRequestErrorWithMessage(
                    "one or more files failed to upload",
                    ApiRequestErrorLevel.ERROR,
                    "SUBMITTALS.CREATE_FAILED_ATTACHMENTS_UPLOAD",
                    400
                );
            }
        }
        // if there are no attachments, then call forward submittal endpoint

        if (attachments.length === 0 && submittal.forwardSubmittalData) {
            await this.logEmailAsForwardSubmittal(
                submittal.forwardSubmittalData,
                projectNrn,
                response.nrn,
                attachments,
                messageNrn,
                isLastModifiedDateSupported
            );
        }
        // why is this needed?
        const filingProjectNrn = this.nrnServiceWrapper.isCloudProject(projectNrn) ? projectNrn : response.nrn;
        await this.fileEmailToProject(filingProjectNrn, messageNrn);
        return { submittalResponse: response };
    }

    async logEmailAsForwardSubmittal(
        forwardSubmittal: LogForwardSubmittalRequest,
        projectNrn: string,
        submittalNrn: string,
        attachments: AttachmentItem[],
        messageNrn: string,
        isLastModifiedDateSupported: boolean
    ): Promise<LogForwardSubmittalResponse> {
        Log.info(`${this.componentName}logEmailAsForwardSubmittal`);
        let attachmentsDetails;
        let forwardSuccess = false;
        let attempt = 0;

        const forwardError: string | null = null;
        if (attachments.length > 0) {
            attachmentsDetails = await this.outlookApiService.getFileAttachmentDetailsMetadata(
                attachments,
                isLastModifiedDateSupported
            );
            forwardSubmittal.attachments = mapAttachmentsMetadataToAttachment(
                attachmentsDetails,
                isLastModifiedDateSupported
            );
        }
        while (attempt < this.maxRetries && !forwardSuccess) {
            try {
                attempt++;
                Log.info(`${this.componentName}lfs Try ${attempt}`);

                await this.logForwardSubmittal(forwardSubmittal, projectNrn, submittalNrn);
                forwardSuccess = true;
            } catch (error) {
                Log.error(`${this.componentName}Logging a forwarded submittal`, error);
                if (attempt >= this.maxRetries) {
                    forwardSuccess = false;

                    const handledErrors: { [index: string]: string } = {
                        403: "SUBMITTALS.UNAUTHORIZED_ERROR",
                        409: "SUBMITTALS.CREATE_FAILED_CONFLICT",
                        500: "SUBMITTALS.UNEXPECTED_ERROR",
                    };

                    const errorCode = (error as any).response?.status || 500;
                    const errorMessage = handledErrors[errorCode] ?? handledErrors[500];

                    throw new Error(errorMessage);
                }
                await delay(this.retryDelay);
            }
        }

        return { forwardSuccess, forwardError };
    }

    async logEmailAsSubmittalReviewerResponse(
        projectNrn: string,
        messageNrn: string,
        submittalNrn: string,
        params: SubmittalReviewResponseBodyParams,
        attachments: AttachmentItem[],
        fileUploadCallback: (isInProgress: boolean, failedIds: string[]) => void,
        isLastModifiedDateSupported: boolean
    ): Promise<WorkflowActionResponse> {
        this.analyticsManager.recordEvent(
            AnalyticsCategoryType.UserActions,
            AnalyticsActionType.FileEmailAsSubmittalReviewResponse
        );

        let attachmentsDetails: AttachmentDetailsMetadata[] = [];
        Log.info(`${this.componentName}logEmailAsSubmittalReviewerResponse`);
        if (attachments.length > 0) {
            attachmentsDetails = await this.outlookApiService.getFileAttachmentDetailsMetadata(
                attachments,
                isLastModifiedDateSupported
            );
            params.attachments = mapAttachmentsMetadataToAttachment(attachmentsDetails, isLastModifiedDateSupported);
        }

        let response: WorkflowActionResponse;

        try {
            response = await this.logSubmittalReviewerResponse(params, projectNrn, submittalNrn);
        } catch (error) {
            Log.error(`${this.componentName}Logging a Submittal Reviewer Response`, error);

            const handledErrors: { [index: string]: string } = {
                403: "SUBMITTALS.RESPONSE.UNAUTHORIZED_ERROR",
                409: "SUBMITTALS.RESPONSE.FAILED_CONFLICT",
                500: "SUBMITTALS.RESPONSE.FAILED_GENERIC",
            };
            const errorCode = (error as any).response?.status || 500;
            const messageToDisplay = handledErrors[errorCode] ?? handledErrors[500];
            if (messageToDisplay) {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.ERROR,
                    messageToDisplay,
                    errorCode
                );
            }

            throw error;
        }

        if (attachments.length > 0) {
            const batchId = v4();
            const result = await this.fileUploadApiService.uploadWorkflowAttachments(
                attachmentsDetails,
                response.uploadFolderNrn,
                fileUploadCallback,
                batchId
            );
            fileUploadCallback(false, result.failedFileIds);
            if (result.failedFileIds.length > 0) {
                throw new ApiRequestErrorWithMessage(
                    "one or more files failed to upload",
                    ApiRequestErrorLevel.ERROR,
                    "SUBMITTALS.RESPONSE.FAILED_ATTACHMENTS_UPLOAD",
                    400
                );
            }
        }
        // is this even required?
        const filingNrn = this.nrnServiceWrapper.isCloudProject(projectNrn) ? projectNrn : submittalNrn;
        await this.fileEmailToProject(filingNrn, messageNrn);

        return response;
    }

    async getSubmittalDetails(submittalNrn: string, projectNrn: string): Promise<SubmittalDetailsResponse> {
        Log.info(`${this.componentName}getSubmittalDetails`);

        const encProjectNrn = encodeURIComponent(projectNrn);
        const encSubmittalNrn = encodeURIComponent(submittalNrn);
        const url = `${this.urlRoot}/v1/projects/${encProjectNrn}/submittals/${encSubmittalNrn}`;
        const options: Request = {
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }

    async getWorkflowActionKeywords(
        projectNrn: string,
        actionType: WorkflowActionType
    ): Promise<ProjectKeywordsResponse> {
        Log.info(`${this.componentName}getWorkflowActionKeywords`);
        const encProjectNrn = encodeURIComponent(projectNrn);
        // actiontype should be lowercased, to be consistent?
        const url = `${this.urlRoot}/v1/projects/${encProjectNrn}/submittals/workflowactions/${actionType}/keywords`;
        const options: Request = {
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }

    //  purgeCacheSubmittals(){
    get cacheDisabled() {
        try {
            const disabled = localStorage.getItem(CACHE_DISABLED);

            return disabled !== null;
        } catch {
            return false;
        }
    }

    cacheExists(): boolean {
        try {
            return localStorage.getItem(CACHED_SUBMITTALS) !== null;
        } catch (error) {
            return false;
        }
    }

    clearCache() {
        // is there an external cache...  ?
        const isCached = localStorage.getItem(CACHED_SUBMITTALS);
        Log.info(`Cache Check ${this._wasCached}`);
        if (!isCached) {
            this._wasCached = false;
        } else {
            localStorage.setItem(CACHED_SUBMITTALS, "");
        }
        Log.infoStyle("Clearing Cache to resync",'red');
        this._submittals = [];
    }

    purgeCacheSubmittals() {
        Log.info("purge");
        if (this._wasCached) {
            Log.info("wasCached");
            if (!this.cacheExists()) {
                // the LS cache was manually deleted, to force reloading...
                Log.info("Cache doesnt exist.");
                this._submittals = [];
                this._wasCached = false;
                return;
            }
        }

        const submittals = this._submittals;
        const oldestTimeStamp = Date.now() - this._expiredSubmittalsMs;

        let size = this._submittals.length ;
        let ix = 0;

        for (let i = 0; i < size; i++) {
            if (submittals[ix]?.timeStamp < oldestTimeStamp || submittals[ix]?.items?.length === 0) {
                submittals?.splice(ix); // remove it
                size--;
            } else {
                ix++;
            }
        }
    }

    saveCacheSubmittals(projectName: any, items: any, timeStamp = Date.now()) {
        const count = items.length;
        const accessed = DateHelper.timeSmall();
        const cacheData = { projectName, count, timeStamp, accessed, items };
        if (this._submittals.length > 10) {
            Log.info("Removing oldest submittals");
            this._submittals = this._submittals.slice(1); // drop the least used...
        }
        // put at end of array
        this._submittals.push(cacheData);

        // save to localstorage
        try {
            localStorage.setItem(CACHED_SUBMITTALS, JSON.stringify(this._submittals));
            this._wasCached = true;
        } catch {}
    }

    loadCacheSubmittals(projectName: string) {
        let cachedSubmittals;
       
        if (this.lock) {
            const result = this.doAbort(); // stop the preceeding load if still in progress
        }
        try {
            this.purgeCacheSubmittals(); 
            if (this.cacheDisabled){
               return []
            }
            // check if a cache exists?
            const ix = this._submittals.findIndex((item: any) => item.projectName === projectName);
            if (ix >= 0) {
                cachedSubmittals = this._submittals[ix];
            }

            if (cachedSubmittals) {
                // remove at current position
                this._submittals.splice(ix, 1);
                // move to top, update the time
                this.saveCacheSubmittals(projectName, cachedSubmittals.items);    
            } else {
                Log.info(`Submittals Cache not found for ${projectName}`);
            }
        } catch {
            Log.info("unknown error retrieving cached submittals");
        }

        return cachedSubmittals?.items;
    }

    async abortRequest() {
        this.requestWrapper.abortCurrentRequest();
    }

    doAbort() {
        Log.infoStyle("Attempting to terminate", "red");
        this.abortCount = this.abortCount + 1;
    }

    async getForwardedSubmittals(projectNrn: string, callback?: (value: number) => void): Promise<Submittal[]> {
        let abort = false;

        Log.info(`${this.componentName}getForwardedSubmittals for project [ ${projectNrn} ] Lock: ${this.lock} `);

        if (this.lock) {
           
            this.doAbort();
        }
        const startTime = Date.now();
        // experience timeouts at 800, and 600 so reducing further
        let maxPageSize = Number(this.maxSubmittalsPageSize);
        let forwardedSubmittals: any = [];

        const aCount = this.abortCount;
        this.lock = true;
        try {
            const isCloudProject = this.nrnServiceWrapper.isCloudProject(projectNrn);
            let items: any = [];
            let retry = 0;
            let count = 0;
            let offsetToken;
            const timeout = 5000;

            while (true) {
                try {
                    const result: any = await this.getSubmittals(projectNrn, maxPageSize, undefined, offsetToken);

                    // any change in the global count, results in this data thrown away
                    if (this.abortCount !== aCount) {
                        Log.infoStyle("Abort in loop", "color: red");
                        abort = true;
                    } else {
                        items = items.concat(result.items);

                        callback ?? items.length;

                        offsetToken = result.paging?.offsetToken;
                    }
                } catch (error) {
                    const statusCode = (error as any).status;

                    if (statusCode >= 500) {
                        // its a timeout, caused by a server failure (insufficent resources - solution increase mem from 512mb on server)
                        // reduce the page size and try again
                        maxPageSize = maxPageSize - 100;
                        if (maxPageSize <= 0) {
                            abort = true;
                        }
                        Log.infoStyle(`Failed to get submittals. Retry with ${maxPageSize}`);
                    } else {
                        retry++;
                        if (retry > 3) {
                            Log.infoStyle(`An unknown error occured code = [${statusCode}] `);
                            this.doAbort();
                        }
                    }
                }

                if (abort || !offsetToken) {
                    break;
                } else {
                    count++;
                    Log.info(`Submittals Page [${count}]: Sum: ${items.length} ${offsetToken ?? ""}`);
                }
            }

            if (!abort) {
                let filter = `${SubmittalStatus.Forwarded}`;

                if (!isCloudProject) {
                    filter = filter.concat(`:${SubmittalStatus.Open}`);
                }
                forwardedSubmittals = items.filter((submittal: any) => filter.includes(submittal.status.type));
            }
        } catch {
            Log.error("Error getting submittals");
        } finally {
            Log.info(`End of submittals ${Date.now() - startTime} ms`);
            this.lock = false;
        }
        if (this.abortCount !== aCount) {
            forwardedSubmittals = undefined;
            Log.infoStyle(`Aborted submittals`, `red`);
            callback ?? ""; // reset the counter
        }

        return forwardedSubmittals;
    }

    private logSubmittal(submittal: LogSubmittalRequest, projectNrn: string): Promise<LogSubmittalResponse> {
        Log.info(`${this.componentName} logSubmittal`);
        const encProjectNrn = encodeURIComponent(projectNrn);
        const options: Request = {
            url: `${this.urlRoot}/v1/projects/${encProjectNrn}/submittals/log`,
            method: "POST",
            body: JSON.stringify(submittal),
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    private logForwardSubmittal(
        submittal: LogForwardSubmittalRequest,
        projectNrn: string,
        submittalNrn: string
    ): Promise<any> {
        Log.info(`${this.componentName}logForwardSubmittal`);
        const encProjectNrn = encodeURIComponent(projectNrn);
        const encSubmittalNrn = encodeURIComponent(submittalNrn);
        const url = `${
            this.urlRoot
        }/v1/projects/${encProjectNrn}/submittals/${encSubmittalNrn}/${WorkflowActionType.Forward.toLocaleLowerCase()}`;

        const options: Request = {
            url: url,
            method: "PUT",
            body: JSON.stringify(submittal),
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.put(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    private async logSubmittalReviewerResponse(
        reviewerResponse: SubmittalReviewResponseBodyParams,
        projectNrn: string,
        submittalNrn: string
    ): Promise<WorkflowActionResponse> {
        Log.info(`${this.componentName}logSubmittalReviewerResponse`);

        const encProjectNrn = encodeURIComponent(projectNrn);
        const encSubmittalNrn = encodeURIComponent(submittalNrn);
        const url = `${
            this.urlRoot
        }/v1/projects/${encProjectNrn}/submittals/${encSubmittalNrn}/${WorkflowActionType.ReviewResponse.toLocaleLowerCase()}`;

        console.log(`Url: ${url}`);

        const options: Request = {
            url: url,
            method: "PUT",
            body: JSON.stringify(reviewerResponse),
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.put(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }
    lastMessageNrn: any = undefined;

    private async fileEmailToProject(projectNrn: string, messageNrn: string): Promise<PostEmailFilingResponse> {
        Log.info(`${this.componentName}fileEmailToProject`);

        if (messageNrn === this.lastMessageNrn) {
            Log.info(`${this.componentName}Warning: ** email is already filed **`);
            return { emailLogIsSupported: true } as PostEmailFilingResponse;
        }
        this.lastMessageNrn = messageNrn;

        try {
            return await this.emailApiService.fileToProject(projectNrn, messageNrn);
        } catch (error) {
            Log.error(`${this.componentName}Logging a submittal - Filing email`, error);

            if (ExpiredSessionError.isInstanceOf(error)) {
                throw error;
            }

            throw new ApiRequestErrorWithMessage(
                (error as any).message,
                ApiRequestErrorLevel.ERROR,
                "SUBMITTALS.CREATE_FAILED_EMAIL_NOT_CREATED",
                (error as any).status
            );
        }
    }

    private async getSubmittals(
        projectNrn: string,
        maxPageSize: number,
        filter: string = "",
        offsetToken: string = ""
    ): Promise<SubmittalsResponse> {
        Log.info(`${this.componentName}getSubmittals`);

        const url = `${this.urlRoot}/v1/projects/${projectNrn}/submittals?status=${filter}&maxSize=${maxPageSize}&offsetToken=${offsetToken}`;

        const options: Request = {
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }
}
