// export let localLoggingEnabled = process?.env?.NODE_ENV !== "production";
export let localLoggingEnabled = false; // use this line instead of above line to turn off extra logging when running locally
let deltaLoggingEnabled = false;

//
try {
    if (!window.localStorage.localLoggingEnabled) {
        window.localStorage.localLoggingEnabled = "false";
    }
    // create an entry for dev to modify in the inspector application tab
    if (!window.localStorage.deltaLoggingEnabled) {
        window.localStorage.deltaLoggingEnabled = "false";
    }
    localLoggingEnabled = window.localStorage.localLoggingEnabled === "true";
    deltaLoggingEnabled = window.localStorage.deltaLoggingEnabled === "true";
} catch {
    console.warn("localStorage is not enabled.");
}

export class Logger {
    private logs: string[] = [];
    private readonly maximumLogLength = 1000;
    private tmLast: number = new Date().getTime();
    enabled = false;

    constructor() {
        Log = this;
        this.enabled = localLoggingEnabled;
    }
    enable(value: boolean) {
        this.enabled = value;
    }

    info(message: string, object?: any): void {
        this.log("INFO", message, object);
        if (this.enabled) {
            let altMessage = message;
            if (message?.includes("Making Newforma Api")) {
                const k = message.indexOf(":") + 1;
                const leftSide = message.slice(0, k);
                const r = message.slice(k);

                altMessage = this.getRequestUrl(JSON.parse(r));
                altMessage = `${leftSide}\r\n${JSON.stringify(altMessage) ?? ""}`;
            }
            console.log(`INFO: ${this.addTimeStamp(altMessage, object)}`);
        }
    }

    warning(message: string, object?: any): void {
        this.log("WARN", message, object);
        if (this.enabled) {
            console.warn(`WARN: ${this.addTimeStamp(message, object)}`);
        }
    }

    error(message: string, object?: any): void {
        if (message.includes('suggested'))
        {
            console.log('a')
           
        }
        this.log("ERROR", message, object);
        if (this.enabled) {
            console.error(`ERROR: ${this.addTimeStamp(message, object)}`);
        }
    }
    // enable custom styles to help dev identify important info in the log
    infoStyle(...args: any): void {
        this.log("INFO", args[0]);
        if (this.enabled) {
            let color = args[1] ?? "";
            if (!color.includes(":")) {
                color = `color: ${color}`;
            }
            const style = color ?? "color: green";
            console.log(`%c${args[0]}`, style);
        }
    }

    addTimeStamp = (msg: string, object: any = undefined): string => {
        return ` ${this.getTimeAndDelta()} ${msg ?? ""} ${object ?? ""}`;
    };

    getLogs(): string[] {
        return this.logs;
    }

    removeField(obj: any, fieldName: string): any {
        if (typeof obj !== "object" || obj === null) {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.removeField(item, fieldName));
        }

        const result: any = {};
        for (const key in obj) {
            if (key !== fieldName) {
                result[key] = this.removeField(obj[key], fieldName);
            } else {
                result[key] = "";
            }
        }
        return result;
    }

    getRequestUrl(obj: any): any {
        if (typeof obj !== "object" || obj === null) {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.getRequestUrl(item));
        }

        const result: any = {};
        for (const key in obj) {
            if (key.toLocaleLowerCase().includes("url")) {
                result[key] = this.getRequestUrl(obj[key]);
            }
        }
        return result;
    }

    private getTimeAndDelta(): string {
        if (!deltaLoggingEnabled) {
            return `[NPC] `;
        }

        const tm = new Date().getTime();

        const delta = tm - this.tmLast;
        this.tmLast = tm;

        return `[NPC ${this.getHMS()} diff ${String(delta).padStart(5, "0")} ms] `;
    }

    private getHMS(): string {
        const tm = new Date();
        return `${tm.toString().substring(16, 24)}.${String(tm.getMilliseconds()).padStart(3, "0")}`;
    }

    private getTimestamp(): string {
        return `[${new Date().toUTCString()}]`;
    }

    private log(prefix: string, message: string, object?: any): void {
        const completeMessage = `${this.getTimestamp()} ${prefix}: ${message} ${
            !object ? "" : JSON.stringify(object, Object.getOwnPropertyNames(object))
        }`;

        if (this.logs.length >= this.maximumLogLength) {
            this.logs.shift();
        }
        this.logs.push(completeMessage);
    }
}
let Log: Logger = new Logger();

export { Log };
