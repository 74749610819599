import "./AssigneeComponent.less";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import {
    CompactPeoplePicker,
    DirectionalHint,
    HoverCard,
    HoverCardType,
    IBasePickerSuggestionsProps,
    IPeoplePickerItemSelectedProps,
    IPersonaProps,
    ISuggestionItemProps,
    ITag,
    Label,
    MessageBarType,
    PeoplePickerItem,
    Persona,
    PersonaSize,
    ValidationState,
    getTheme,
} from "office-ui-fabric-react";
import * as React from "react";
import { FormValidationHelpers } from "../../../helpers/FormValidationHelpers";

import { Logger, Log } from "../../../services/Logger";
import { IProjectsService } from "../../../services/NewformaApi/IProjectsService";
import LabelComponent from "../label/LabelComponent";
import LinkComponent from "../linkComponent/LinkComponent";

export interface AssigneeComponentProps extends LocalizeContextProps {
    className?: string;
    assignees: IPersonaProps[];
    formValidationHelpers: FormValidationHelpers;
    selectedProject: ITag | null;
    currentUserEmail: string | null;
    disabled: boolean;
    onAssigneeChanged: (assignees: IPersonaProps[]) => void;
    onError: (message: string | null, type: MessageBarType) => void;
    logger: Logger;
    projectsService: IProjectsService;
    label: string;
    required: boolean;
    showAssignToMe?: boolean;
    maxAllowed?: number;
    type?: string;
    sameCcAndTo?: boolean;
    panelString?: string;
    sameToAndCcEmail?: String[];
    errorMessage?: string;
    emailGroup?: IPersonaProps[];
}

export interface AssigneeComponentState {
    assignees: IPersonaProps[];
    validationErrors: IPersonaProps[];
    focusRefreshFlag: Boolean;
    shouldShowEmailFormatErrorMessage: Boolean;
}

class AssigneeComponent extends React.Component<AssigneeComponentProps, AssigneeComponentState> {
    constructor(props: AssigneeComponentProps, context: AssigneeComponentState) {
        super(props, context);

        this.state = {
            assignees: [],
            validationErrors: [],
            focusRefreshFlag: true,
            shouldShowEmailFormatErrorMessage: false,
        };
    }

    componentId = (Math.random() * 100000).toString();

    log(msg: string): void {
        console.log(`[${this.props.type}] ${msg}`);
    }

    async componentDidMount() {
        this.setState({ assignees: this.props.assignees, focusRefreshFlag: !this.state.focusRefreshFlag });
        this.emailChecks();
    }

    async componentDidUpdate(
        prevProps: Readonly<AssigneeComponentProps>,
        prevState: Readonly<AssigneeComponentState>,
        snapshot?: any
    ): Promise<void> {
        if (prevProps === this.props) {
            return;
        }

        if (prevProps.assignees !== this.props.assignees) {
            this.setState({ assignees: this.props.assignees, focusRefreshFlag: !this.state.focusRefreshFlag });
            this.emailChecks(this.props.assignees);
            return;
        }

        if (
            prevState.focusRefreshFlag !== this.state.focusRefreshFlag ||
            prevProps.sameCcAndTo !== this.props.sameCcAndTo
        ) {
            this.emailChecks();
        }
    }
    setFormatError(isFormatError: boolean) {
        this.setState({ shouldShowEmailFormatErrorMessage: isFormatError });
    }

    async onAssignedToFiltered(filter: string, selectedItems?: IPersonaProps[]): Promise<IPersonaProps[]> {
        this.log("onAssignedToFiltered");
        const projectNrn = this.props.selectedProject?.key;
        if (!filter || !projectNrn) {
            return [];
        }
        let personaProps: IPersonaProps[];
        const currentAssigneeNrns: (string | undefined)[] = this.props.assignees.map(
            (assignee) => (assignee as any).data?.nrn
        );
        try {
            const teamMembers = await this.props.projectsService.getAllTeamMembersNormalized(projectNrn, filter);

            personaProps = this.props.formValidationHelpers.getPersonaPropsFromTeamMembers(teamMembers);
            personaProps = personaProps.filter((personaProp) => {
                if (!(personaProp as any).data?.nrn) {
                    return false;
                }
                return !currentAssigneeNrns.includes((personaProp as any).data?.nrn);
            });
        } catch {
            personaProps = [];
        }

        return personaProps;
    }

    private onAssignedToChanged(items?: IPersonaProps[]) {
        if (!items || !items.length) {
            this.updateAssignees([]);
            this.setFormatError(false);

            return;
        }

        // remove the last entry from the items list..
        let recentlyAdded = items.pop() as IPersonaProps & { data?: { email?: string } };
        // Checks for any duplicates, for each item, it compares the email, AND the text
        // and if any matches... filters the list to remove them
        let uniqueItems = items.filter((item) => {
            if (item.text === recentlyAdded.data?.email) {
                return false;
            }
            if (item.text === recentlyAdded.text) {
                return false;
            }
            if ((item as any).data?.email === recentlyAdded.text) {
                recentlyAdded = item; // if there was an alternate 'expanded' form of the recent item, remove it.. but update the recent item
                return false;
            }
            return true;
        });
        uniqueItems.push(recentlyAdded);
        if (this.props.maxAllowed) {
            uniqueItems = uniqueItems.slice(-this.props.maxAllowed);
        }
        this.updateAssignees(uniqueItems);
    }
    // used to keep track of focus
    onFocus() {
        this.setState({ focusRefreshFlag: true });
    }
    onBlur() {
        this.setState({ focusRefreshFlag: false });
    }
    onCreateGenericItem(input: string, validationState: ValidationState): any {
        return {
            text: input,
            ValidationState: validationState,
        };
    }

    validateEmailInput(input: string): ValidationState {
        const result = this.props.formValidationHelpers.validateEmailInput(input);

        if (result !== ValidationState.valid) {
            this.props.onError("Validation Error", MessageBarType.error);
        }
        this.emailChecks();
        return result;
    }

    validateToAndCcEmails(currentAssignees: IPersonaProps[] | null): Number[] {
        const emailErrors: any[] = [];
        const errors: any[] = [];
        let isSame: boolean | undefined = false;
        let isFormatError = false;

        this.setState({ validationErrors: [] });
        if (currentAssignees) {
            // const currentAssignees = assignees ?? this.state.assignees;
            const emailGroups = this.props.emailGroup;

            currentAssignees.forEach((assignee, index) => {
                const text = assignee.secondaryText ?? assignee.text;
                this.log(` ${index} : ${text}`);
                if (text) {
                    const isValidGroup = emailGroups?.some((p) => p.text === text);

                    if (!this.props.formValidationHelpers.isValidEmail(text, isValidGroup)) {
                        isFormatError = true;
                    }
                    isSame = this.props.sameToAndCcEmail?.some((p) => p === text);
                    const validatedType = this.props.formValidationHelpers.validateEmailInput(
                        text,
                        isValidGroup,
                        (this.props.sameCcAndTo ?? false) && isSame
                    );
                    emailErrors.push(validatedType);
                    if (validatedType === ValidationState.invalid) {
                        errors.push(assignee);
                    }
                }
            });

            if (errors.length > 0) {
                this.props.onError("something happened", MessageBarType.error);
            }
        }
        
        this.setFormatError((this.props.type === "cc") ? (isFormatError && !isSame) : isFormatError)

        this.setState({ validationErrors: errors });
        return emailErrors;
    }

    addStylingForValidation(errors?: Number[]): any {
        if (!errors || errors.length < 1) {
            return;
        }

        const theType = this.props.type;
        const section = document.getElementById(this.componentId);

        let isError = errors.indexOf(1) !== -1;
        if (theType === "cc") {
            isError = this.props.sameCcAndTo || false;
        }
        section?.classList.toggle("invalidError", isError);
    }

    emailChecks(assignees?: IPersonaProps[]) {
        const emailErrors = this.validateToAndCcEmails(assignees ?? this.state.assignees);

        this.addStylingForValidation(emailErrors);
    }

    renderSuggestionsItem(props: IPersonaProps, itemProps: ISuggestionItemProps<any>): JSX.Element {
        const discipline = (props as any).data?.discipline ? `(${(props as any).data?.discipline})` : "";
        const text = `${props.text} ${discipline}`.trim();
        const updatedProps: IPersonaProps = {
            ...props,
            text,
            size: PersonaSize.size24,
            className: "newforma-assigneeSuggestionsItem",
        };
        return <Persona {...updatedProps} />;
    }

    renderItem(props: any): JSX.Element {
        let sameCcAndTo = false;
        let sameCcAndToEmails: String[] = [];

        if (this.props.sameToAndCcEmail) {
            sameCcAndToEmails = this.props.sameToAndCcEmail;
        }
        sameCcAndTo = sameCcAndToEmails.some((toEmail) => (props.item.secondaryText || props.item.text) === toEmail);
        /*
        sameCcAndTo = sameCcAndToEmails.some((toEmail) => {
            return (
                (props.item.secondaryText && props.item.secondaryText === toEmail) ||
                (!props.item.secondaryText && props.item.text === toEmail)
            );
        });*/

        // Check if both 'newforma-ccPicker' OR 'newforma-fromPicker' classes are present
        // No CC styling if its the 'From'...
        const isNotFrom = !this.props.className?.includes("newforma-fromPicker");
        //    this.props.className?.includes("newforma-ccPicker") ||
        //    this.props.className?.includes("newforma-fromPicker");

        const updatedProps: IPeoplePickerItemSelectedProps = {
            ...props,
            item: {
                ...props.item,
                showSecondaryText: false,
                onRenderPrimaryText: () => {
                    return (
                        <span className={sameCcAndTo && isNotFrom ? "newforma-invalidCC" : ""}>{props.item.text}</span>
                    );
                }, // this is needed to prevent the default behavior of showing a tooltip for overflow
            },
        };
        return (
            <HoverCard
                plainCardProps={{
                    onRenderPlainCard: this.renderAssigneeCard.bind(this),
                    renderData: props.item,
                    directionalHint: DirectionalHint.topLeftEdge,
                    gapSpace: 5,
                }}
                instantOpenOnClick
                type={HoverCardType.plain}
                key={`hovercard-${props.item.text}`}
            >
                <PeoplePickerItem {...updatedProps} />
            </HoverCard>
        );
    }

    renderAssigneeCard(personaProps: IPersonaProps): JSX.Element {
        const data = (personaProps as any).data;
        const text = data?.email || personaProps.text;
        const secondaryText = data?.discipline;
        const updatedProps: IPersonaProps = {
            ...personaProps,
            text,
            secondaryText,
            showSecondaryText: !!secondaryText,
            size: PersonaSize.size24,
            className: "newforma-assigneeSuggestionsItem",
            coinProps: {
                className: "newforma-hoverCoin",
            },
        };
        return <Persona {...updatedProps} />;
    }

    private async assignToMe(): Promise<void> {
        let currentAssignees = this.props.assignees;
        const projectNrn = this.props.selectedProject?.key;
        const email = this.props.currentUserEmail;
        if (this.props.disabled) {
            return;
        }
        if (!projectNrn || !email) {
            this.props.onError(
                this.props.translate("ACTION_ITEM.ASSIGN_TO_ME_ERROR") as string,
                MessageBarType.severeWarning
            );
            return;
        }
        try {
            const teamMembers = await this.props.projectsService.getTeamMembersNormalized(projectNrn, email);
            let assignees;
            currentAssignees = currentAssignees.filter((assignee) => {
                return !(assignee.text === email);
            });
            if (teamMembers.length === 1) {
                const myPersonaProp = this.props.formValidationHelpers.getPersonaPropsFromTeamMembers(
                    teamMembers
                )[0] as any;
                // remove existing record from list
                currentAssignees = currentAssignees.filter((assignee) => {
                    return !(myPersonaProp.data.nrn === (assignee as any).data?.nrn);
                });
                assignees = [...currentAssignees, myPersonaProp];
            } else {
                assignees = [...currentAssignees, { text: email }];
            }
            this.updateAssignees(assignees);
        } catch (error) {
            this.props.logger.error("AssigneeComponent error", error);
            this.updateAssignees([...currentAssignees, { text: email }]);
        }
    }

    private updateAssignees(assignees: IPersonaProps[]): void {
        this.setState({ assignees });
        // ONLY after any change... redo the validation
        this.emailChecks(assignees);

        this.props.onAssigneeChanged(assignees);
    }

    private getPickerSuggestionProps(): IBasePickerSuggestionsProps {
        return {
            suggestionsHeaderText: this.props.translate("ACTION_ITEM.TEAM_MEMBER_HEADER") as string,
            noResultsFoundText: this.props.selectedProject
                ? (this.props.translate("ACTION_ITEM.NO_RESULTS_FOUND") as string)
                : (this.props.translate("ACTION_ITEM.NO_PROJECT_SELECTED") as string),
            loadingText: this.props.translate("ACTION_ITEM.LOADING") as string,
            showRemoveButtons: false,
        };
    }

    private renderAssignToMeButton(): JSX.Element {
        const ASSIGN_TO_ME = this.props.translate("ACTION_ITEM.ASSIGN_TO_ME");
        return (
            <>
                {this.props.disabled ? (
                    <Label className="newforma-assignToMeDisabled">{ASSIGN_TO_ME}</Label>
                ) : (
                    <LinkComponent
                        text={`${ASSIGN_TO_ME}`}
                        onClick={this.assignToMe.bind(this)}
                        className="newforma-assignToMe"
                    />
                )}
            </>
        );
    }

    renderFormatError(): JSX.Element {
        return (
            <>
                {this.state.shouldShowEmailFormatErrorMessage ? (
                    /*  "Email address(es) is/are not correctly formatted", */
                    <p className="emailValidationMessage">{this.props.translate("SHARED.ERRORS.EMAIL_VALIDATION")}</p>
                ) : null}
            </>
        );
    }

    renderCcSameError(): JSX.Element {
        return (
            <>
                {this.props.sameCcAndTo &&
                this.props.assignees.length > 0 &&
                !this.state.shouldShowEmailFormatErrorMessage ? (
                    /* "Email addresses in the To and Cc fields cannot be the same" */
                    <p className="emailValidationMessage">
                        {this.props.translate("SHARED.ERRORS.TO_AND_CC_VALIDATION_1")}
                        <span className="emailValidationBoldMessage">
                            {this.props.translate("SHARED.ERRORS.TO_AND_CC_VALIDATION_2")}
                        </span>
                        {this.props.translate("SHARED.ERRORS.TO_AND_CC_VALIDATION_3")}
                        <span className="emailValidationBoldMessage">
                            {this.props.translate("SHARED.ERRORS.TO_AND_CC_VALIDATION_4")}
                        </span>
                        {this.props.translate("SHARED.ERRORS.TO_AND_CC_VALIDATION_5")}
                    </p>
                ) : null}
            </>
        );
    }

    render(): JSX.Element {
        const theme = getTheme();
        return (
            <div className={`${this.props.className} newforma-assigneeComponent`} id={this.componentId}>
                <LabelComponent text={this.props.label} required={this.props.required} />

                <CompactPeoplePicker
                    className={`newforma-focused`}
                    pickerSuggestionsProps={this.getPickerSuggestionProps()}
                    selectedItems={this.state.assignees}
                    onResolveSuggestions={this.onAssignedToFiltered.bind(this)}
                    onChange={this.onAssignedToChanged.bind(this)}
                    onValidateInput={this.validateEmailInput.bind(this)}
                    resolveDelay={300}
                    createGenericItem={this.onCreateGenericItem.bind(this)}
                    disabled={this.props.disabled}
                    onRenderSuggestionsItem={this.renderSuggestionsItem.bind(this)}
                    onRenderItem={this.renderItem.bind(this)}
                    onFocus={this.onFocus.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    inputProps={{
                        onFocus: (ev: React.FocusEvent<HTMLInputElement>) => this.emailChecks(),
                    }}
                    styles={{
                        root: {
                            selectors: {
                                ":focus-within": {
                                    borderRadius: "2px",
                                    margin: "-2px",
                                },
                            },
                        },
                    }}
                />
                {this.renderFormatError()}

                {this.renderCcSameError()}

                {this.props.showAssignToMe ? this.renderAssignToMeButton() : null}
            </div>
        );
    }
}

export default withLocalize(AssigneeComponent);
