export function delay(ms: number) {
    return new Promise((resolve) => {
        const timeoutId = setTimeout(resolve, ms);
        return () => clearTimeout(timeoutId);
    });
}
import * as _ from "lodash";

export function objectDiff(obj1: any, obj2: any) {
    const changes: any = {};

    for (const key in obj1) {
        if (!_.isEqual(obj1[key], obj2[key])) {
            changes[key] = {
                oldValue: obj1[key],
                newValue: obj2[key],
            };
        }
    }

    for (const key in obj2) {
        if (!obj1.hasOwnProperty(key)) {
            changes[key] = {
                oldValue: undefined,
                newValue: obj2[key],
            };
        }
    }

    return changes;
}

export function isDiffEmpty(diff: any) {
    return Object.keys(diff).length === 0;
}
