import {
    DirectionalHint,
    HoverCard,
    HoverCardType,
    Icon,
    MessageBarType,
    Separator,
    Stack,
    getTheme,
} from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Logger } from "../../../services/Logger";
import "./respondCloseSubmittal.less";
import TranslatedDatePickerComponent from "../../shared/translatedDatePicker/TranslatedDatePickerComponent";
import { ElementVisibility } from "../../../models/shared/ElementVisibility";
import FromAndToComponent from "../../shared/fromAndToComponent/FromAndToComponent";
import { EmailRecipients } from "../../../models/shared/FileTransferEmailFieldsDetails";
import { IPersonaProps, ITag, Label, TextField } from "office-ui-fabric-react";
import { OfficeWrapper, MailboxItem } from "../../../services/OfficeWrapper";
import { FormValidationHelpers } from "../../../helpers/FormValidationHelpers";
import { IProjectsService } from "../../../services/NewformaApi/IProjectsService";
import LabelComponent from "../../shared/label/LabelComponent";
import * as _ from "lodash";
import { KeywordListType } from "../../../models/ProjectKeywordsResponse";
import { ExpiredSessionError } from "../../../models/ExpiredSessionError";
import { ProjectHelper } from "../../../helpers/ProjectHelper";
import { ConfigurationService } from "../../../services/ConfigurationService";
import HTMLEditor from "../../shared/editor/Editor";
import { SubmittalsApiService } from "../../../services/NewformaApi/SubmittalsApiService";
import ChipPickerComponent from "../../shared/chipPicker/ChipPickerComponent";

export interface RespondCloseSubmittalProps extends LocalizeContextProps {
    shouldShowSubmittalResponse: () => void;
    theme: string;
    logger: Logger;
    updateRespondAndCloseDate: (date: Date | undefined) => void;
    respondAndCloseDate: Date | undefined;
    updateEmails: (emails: EmailRecipients) => void;
    priorEmails: EmailRecipients;
    officeWrapper: OfficeWrapper;
    formValidationHelpers: FormValidationHelpers;
    mailboxItem: MailboxItem | null;
    projectsService: IProjectsService;
    selectedProject: ITag | null;
    onExpiredSession: () => void;
    onFormValidationChange: (isValid: boolean) => void;
    respondAndCloseResponse: string | undefined;
    isFiling: boolean;
    updateLoadingState: (loadingPurposes: boolean) => void;
    updateKeywords: (option: IPersonaProps[], keywords: IPersonaProps[]) => void;
    onShowToast: (message: string | null, type: MessageBarType) => void;
    respondAndCloseSelectedKeyword: IPersonaProps[];
    respondAndCloseKeywords: IPersonaProps[];
    updateClearValue: () => void;
    updateRespondAndCloseResponse: (text: string | undefined) => void;
    configService: ConfigurationService;
    submittalsApiService: SubmittalsApiService;
}

export interface RespondCloseSubmittalState {
    date: Date | undefined;
    from: IPersonaProps[];
    to: IPersonaProps[];
    cc: IPersonaProps[];
    shouldClearEmails: boolean;
    isActionApproved: boolean;
    responseSummary: string | undefined;
    keywords: IPersonaProps[];
    selectedkeywords: IPersonaProps[];
    isLoadingKeywords: boolean;
    allowCustomKeywords: boolean;
}

class RespondCloseSubmittal extends React.Component<RespondCloseSubmittalProps, RespondCloseSubmittalState> {
    defaultState: RespondCloseSubmittalState = {
        date: undefined,
        from: [],
        to: [],
        cc: [],
        shouldClearEmails: false,
        isActionApproved: true,
        responseSummary: "",
        keywords: [],
        selectedkeywords: [],
        isLoadingKeywords: false,
        allowCustomKeywords: false,
    };
    constructor(props: RespondCloseSubmittalProps) {
        super(props);
        this.state = this.defaultState;
    }

    async componentDidMount() {
        this.props.logger.info("Respond Close component mounted");
        document.querySelector(".submittalReviewResponseComponent")?.scrollTo({
            top: 0,
            behavior: "auto",
        });

        if (this.props.selectedProject && this.props.respondAndCloseSelectedKeyword.length === 0) {
            await this.getKeywords(this.props.selectedProject.key, this.props.selectedProject.name);
        } else if (this.props.respondAndCloseSelectedKeyword && this.props.respondAndCloseKeywords) {
            this.setState({
                selectedkeywords: this.props.respondAndCloseSelectedKeyword,
                keywords: this.props.respondAndCloseKeywords,
            });
        }
        this.setEmailsState("to", this.props.priorEmails.to);
        this.setEmailsState("cc", this.props.priorEmails.cc);
        if (this.props.respondAndCloseResponse) {
            this.setState({ responseSummary: this.props.respondAndCloseResponse });
        }
        if (this.props.respondAndCloseDate) {
            this.setState({ date: this.props.respondAndCloseDate });
        }
    }

    private async getKeywords(projectNrn: string, projectName: string): Promise<void> {
        this.props.logger.info(`Loading keywords in forward submittal for selected project: ${projectName}`);
        this.setState({ isLoadingKeywords: true });
        this.props.updateLoadingState(true);
        try {
            const keywordsResponse = await this.props.projectsService.getProjectKeywords(
                projectNrn,
                KeywordListType.SubmittalKeyword
            );
            const sortedKeywords = ProjectHelper.sortKeywords(keywordsResponse);
            const keywords: IPersonaProps[] = sortedKeywords.map((keyword) => ({
                text: keyword.name,
                data: keyword,
            }));
            this.setState({ keywords, allowCustomKeywords: keywordsResponse.allowCustom });
        } catch (error) {
            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }

            this.props.logger.error("ForwardSubmittalComponent Error loading keywords", error);

            this.clearKeywords();
            this.props.onShowToast(
                this.props.translate("SUBMITTALS.LOADING_KEYWORDS_FAILED") as string,
                MessageBarType.severeWarning
            );
        } finally {
            this.setState({ isLoadingKeywords: false });
            this.props.updateLoadingState(false);
        }
    }

    private clearKeywords(): void {
        this.setState({
            keywords: [],
            selectedkeywords: [],
        });
    }

    private onToChange(items?: IPersonaProps[]): void {
        this.setState(
            {
                to: items || [],
                shouldClearEmails: false,
            },
            () => {
                this.props.updateEmails({ to: this.state.to, cc: this.state.cc });
                this.updateForwardButtonState();
            }
        );
    }

    private onCcChange(people: IPersonaProps[]): void {
        this.setState(
            {
                cc: people || [],
                shouldClearEmails: false,
            },
            () => {
                this.props.updateEmails({ to: this.state.to, cc: this.state.cc });
                this.updateForwardButtonState();
            }
        );
    }

    private onFromChange(people: IPersonaProps[]): void {
        this.setState({ from: people });
    }

    private onBackButtonClicked(): void {
        this.props.updateEmails({ to: this.state.to, cc: this.state.cc });
        this.props.updateRespondAndCloseResponse(this.state.responseSummary);
        this.props.updateClearValue();
        this.props.shouldShowSubmittalResponse();
    }

    private onDateChange(date: Date | undefined): void {
        this.setState({ date: date });
        this.props.updateRespondAndCloseDate(date);
    }

    private updateForwardButtonState = () => {
        const isFormValid = this.isFormValid();
        this.props.onFormValidationChange(isFormValid);
    };

    private isFormValid(): boolean {
        const { to, cc, date } = this.state;
        const isCcValid = _.isEmpty(cc) || this.props.formValidationHelpers.areAssigneesValid(cc, true);
        const isValid = !!(
            _.size(to) &&
            date !== undefined &&
            this.props.formValidationHelpers.areAssigneesValid(to, true) &&
            isCcValid
        );

        return isValid;
    }

    private setEmailsState(key: keyof EmailRecipients, emails?: IPersonaProps[]): void {
        if (_.size(emails)) {
            this.setState((prevState) => ({
                ...prevState,
                [key]: emails || [],
            }));
        }
    }

    private renderHint(): JSX.Element {
        return (
            <div className="newforma-hintText">
                <div>
                    {this.props.translate("SUBMITTALS.RESPOND_CLOSE.ACTION_HINT1") as string}
                    <b> {this.props.translate("SUBMITTALS.RESPOND_CLOSE.APPROVED") as string}</b>
                    {this.props.translate("SUBMITTALS.RESPOND_CLOSE.ACTION_HINT2") as string}
                </div>
            </div>
        );
    }

    private onRemarksChange(responseSummary?: string): void {
        this.props.updateRespondAndCloseResponse(responseSummary);
        this.setState({ responseSummary });
    }

    private onKeywordsChanged(option: IPersonaProps[]): void {
        this.setState({ selectedkeywords: option });
        this.props.updateKeywords(option, this.state.keywords);
        this.updateForwardButtonState;
    }

    render(): JSX.Element {
        const theme = getTheme();
        return (
            <div className="newforma-respondCloseSubmittalContainer margin-bottom">
                <div className="headerText">
                    <Stack className="headerText">
                        <Separator className="custom-separator-bg" alignContent="start">
                            <div className="headerText">
                                {this.props.translate("SUBMITTALS.RESPOND_CLOSE.HEADER_TEXT")}
                            </div>
                        </Separator>
                    </Stack>
                </div>
                <div
                    className="newforma-respondCloseBackContainer"
                    onClick={() => this.onBackButtonClicked()}
                    data-theme={this.props.theme}
                >
                    <Icon iconName="NavigateBack" className="backIcon" data-theme={this.props.theme} />
                    <span className="rfiText">{this.props.translate("SUBMITTALS.RESPOND_CLOSE.BACK_BUTTON")}</span>
                </div>
                <TranslatedDatePickerComponent
                    date={this.state.date}
                    label={this.props.translate("SUBMITTALS.RESPOND_CLOSE.DATE") as string}
                    disabled={false}
                    onDateChange={this.onDateChange.bind(this)}
                    className="newforma-aiSpacing"
                    required={true}
                    clearDateButtonVisibility={ElementVisibility.None}
                />
                <FromAndToComponent
                    className={"newforma-formSpacing"}
                    isFromRequired={false}
                    isToRequired={true}
                    isCcRequired={false}
                    includeCc={true}
                    officeWrapper={this.props.officeWrapper}
                    disabled={this.props.isFiling}
                    formValidationHelpers={this.props.formValidationHelpers}
                    mailboxItem={this.props.mailboxItem}
                    onFromChange={this.onFromChange.bind(this)}
                    onToChange={this.onToChange.bind(this)}
                    onCcChange={this.onCcChange.bind(this)}
                    logger={this.props.logger}
                    projectsService={this.props.projectsService}
                    disableOnError={true}
                    onError={this.props.onShowToast}
                    project={this.props.selectedProject}
                    shouldHideFrom={true}
                    shouldClearEmails={this.state.shouldClearEmails}
                    EmailRecipients={this.props.priorEmails}
                    isForwardInProgress={true}
                />
                <div className="newforma-headerContainer">
                    <LabelComponent
                        text={this.props.translate("SUBMITTALS.RESPOND_CLOSE.ACTION") as string}
                        required={true}
                    />
                    <HoverCard
                        plainCardProps={{
                            onRenderPlainCard: this.renderHint.bind(this),
                            directionalHint: DirectionalHint.topRightEdge,
                            gapSpace: 5,
                            calloutProps: {
                                isBeakVisible: true,
                                beakWidth: 45,
                                coverTarget: false,
                                directionalHintFixed: true,
                                styles: {
                                    calloutMain: {
                                        color: `${theme.palette.neutralPrimary} !important`,
                                    },
                                },
                            },
                            style: { minHeight: 50 },
                        }}
                        instantOpenOnClick
                        type={HoverCardType.plain}
                    >
                        <Icon iconName="Info" className="newforma-hintIcon" />
                    </HoverCard>
                </div>

                <TextField
                    className="newforma-submittalSpacing newforma-submittalAction"
                    id="submittal-action"
                    value={this.props.translate("SUBMITTALS.RESPOND_CLOSE.APPROVED") as string}
                    disabled={true}
                    autoAdjustHeight={false}
                />
                <br />
                <Label required={false}>{this.props.translate("SUBMITTALS.RESPOND_CLOSE.SUMMARY") as string}</Label>
                <HTMLEditor
                    value={this.state.responseSummary}
                    onRemarksUpdate={this.onRemarksChange.bind(this)}
                    configService={this.props.configService}
                    isFiling={this.props.isFiling}
                />
                <ChipPickerComponent
                    className="newforma-formSpacing newforma-submittalRevResponseKeywords"
                    required={false}
                    disabled={this.props.isFiling}
                    items={this.state.keywords}
                    selectedItems={this.state.selectedkeywords}
                    onSelectedItemsChanged={this.onKeywordsChanged.bind(this)}
                    label={this.props.translate("SUBMITTALS.RESPOND_CLOSE.KEYWORDS") as string}
                    allowCustomInput={this.state.allowCustomKeywords}
                />
            </div>
        );
    }
}

export default withLocalize(RespondCloseSubmittal);
