import "./SubmittalComponent.less";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { MailboxItem, OfficeWrapper } from "../../services/OfficeWrapper";
import { AppPage } from "../shared/navigationHeader/NavigationHeaderComponent";
import { ConfigurationsApiService } from "../../services/NewformaApi/ConfigurationsApiService";
import {
    DefaultButton,
    IPersonaProps,
    Label,
    MessageBarType,
    ProgressIndicator,
    TextField,
} from "office-ui-fabric-react";
import { FormValidationHelpers } from "../../helpers/FormValidationHelpers";
import { Logger } from "../../services/Logger";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import { IProjectsService } from "../../services/NewformaApi/IProjectsService";
import BoundDateRangePickerComponent from "../shared/boundDateRangePickers/BoundDateRangePickerComponent";
import {
    DetailedKeyword,
    DetailedKeyword as ProjectKeyword,
    KeywordListType,
    ProjectKeywordsResponse,
} from "../../models/ProjectKeywordsResponse";
import { DateHelpers } from "../../helpers/DateHelpers";
import ChipPickerComponent from "../shared/chipPicker/ChipPickerComponent";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../../services/AnalyticsManager";
import { SubmittalsApiService } from "../../services/NewformaApi/SubmittalsApiService";
import { ApiRequestErrorLevel } from "../../models/ApiRequestErrorWithMessage";
import AttachmentsComponent from "../shared/attachments/AttachmentsComponent";
import SpecSectionComponent from "../shared/specSection/SpecSectionComponent";
import { LogSubmittalRequest } from "../../models/workflow/submittal/LogSubmittalRequest";
import SuggestedProjectPickerComponent from "../shared/suggestedProjectPicker/SuggestedProjectPickerComponent";
import { SmartFilingManager } from "../../services/SmartFiling/SmartFilingManager";
import FromAndToComponent from "../shared/fromAndToComponent/FromAndToComponent";
import { NrnServiceWrapper } from "../../services/NrnServiceWrapper";
import { NumberEntry } from "../../models/projects/ProjectSettingsResponse";
import { AttachmentItem } from "../../models/shared/AttachmentList";
import KeywordsDropdown from "../shared/keywordsDropdown/KeywordsDropdown";
import { MsGraphApiService } from "../../services/MsGraphApiService";
import ReminderComponent from "../shared/reminder/ReminderComponent";
import { ProjectHelper } from "../../helpers/ProjectHelper";
import { ProjectsCacheKeys } from "../../models/StorageKeys";
import { InvalidateCacheService } from "../../services/NewformaApi/InvalidateCacheService";
import ForwardCheckbox from "../shared/checkbox/forwardCheckbox";
import ForwardSubmittal from "./forwardSubmittal/forwardSubmittal";
import { EmailRecipients } from "../../models/shared/FileTransferEmailFieldsDetails";
import { ProjectITag } from "../shared/suggestedProjectPicker/SuggestedProjectPickerComponent";
import HTMLEditor from "../shared/editor/Editor";
import { ConfigurationService } from "../../services/ConfigurationService";
import { LogForwardSubmittalRequest } from "../../models/workflow/submittal/ForwardSubmittalRequest";
import { LogForwardSubmittalResponse } from "../../models/workflow/submittal/ForwardSubmittalResponse";
import { AttachmentDataHelpers } from "../../helpers/AttachmentDataHelpers";
import { ITagWithNixEnabled } from "../../models/shared/ITagWithNixEnabed";
import { TeachingBubblesTypes } from "../../models/TeachingBubblesTypes";

export interface SubmittalComponentProps extends LocalizeContextProps {
    logger: Logger;
    officeWrapper: OfficeWrapper;
    configurationService: ConfigurationsApiService;
    formValidationHelpers: FormValidationHelpers;
    projectsService: IProjectsService;
    onExpiredSession: () => void;
    dateHelpers: DateHelpers;
    analyticsManager: AnalyticsManager;
    submittalsApiService: SubmittalsApiService;
    smartFilingManager: SmartFilingManager;
    onSetNavigationPage: (page: AppPage) => void;
    onShowToast: (message: string | null, type: MessageBarType) => void;
    showMultipleToasts: (toasts: { message: string; type: MessageBarType }[]) => void;
    mailboxItem: MailboxItem | null;
    nrnServiceWrapper: NrnServiceWrapper;
    msGraphApiService: MsGraphApiService;
    theme: string;
    invalidateCacheService: InvalidateCacheService;
    isFilePathInAttachmentsSupported?: boolean;
    isFileTransferAndEditorSupported: boolean;
    isForwardSubmittalSupported: boolean;
    configService: ConfigurationService;
    showForwardSubmittalTeachingBubble: boolean;
    onDismissTeachingBubble: () => void;
}

export interface SubmittalComponentState {
    projects: ITagWithNixEnabled[];
    project: ITagWithNixEnabled | null;
    subject: string | undefined;
    description: string | undefined;
    from: IPersonaProps[];
    to: IPersonaProps[];
    isFiling: boolean;
    isLoading: boolean;
    isLoadingKeywords: boolean;
    internalNotes: string | undefined;
    receivedDate: Date | undefined;
    dueDate: Date | undefined;
    purposes: DetailedKeyword[];
    selectedPurpose: DetailedKeyword | null;
    disciplines: IPersonaProps[];
    selectedDisciplines: IPersonaProps[];
    keywords: IPersonaProps[];
    selectedKeywords: IPersonaProps[];
    senderNumber: string | undefined;
    attachments: AttachmentItem[];
    fileUploadIsInProgress: boolean;
    failedUploadAttachmentNames: string[];
    specSectionName: string;
    submittalNumber: string;
    revisionNumber: string;
    isRevisionNumberEnabled: boolean;
    numberEntryMode: NumberEntry | null; // null for cloud projects
    reminderDays: number | undefined;
    isCloudProject: boolean;
    shouldClearSpecSection: boolean;
    isLoadingProjects: boolean;
    isSpecSectionRequired: boolean;
    shouldForwardSubmittal: boolean;
    shouldShowForwardSubmittalOptions?: boolean;
    selectedSpecSection: ITagWithNixEnabled | undefined;
    selectedItemNumber: string | undefined;
    selectedRevisionNumber: string | undefined;
    emailRecipients: EmailRecipients;
    forwardSubmittalBackButtonClicked: boolean;
    projectsWithSuggestedToBeRendered: ProjectITag[];
    forwardSubmittalSelectedPurpose: DetailedKeyword | null;
    forwardSubmittalPurposes: ProjectKeyword[];
    forwardEmailRecipients: EmailRecipients;
    forwardDueDate: Date | undefined;
    forwardRemarks: string | undefined;
    forwardReminderDays: number | undefined;
    shouldClearForwardValues: boolean;
    isLoadingPurposes: boolean;
    isForwardFormValid: boolean;
    areToAndCCSame: boolean;
    isDateInPast: boolean;
}

class SubmittalComponent extends React.Component<SubmittalComponentProps, SubmittalComponentState> {
    defaultState: SubmittalComponentState = {
        projects: [],
        project: null,
        subject: "",
        description: "",
        from: [],
        to: [],
        isLoading: true,
        isFiling: false,
        isLoadingKeywords: false,
        internalNotes: "",
        receivedDate: undefined,
        dueDate: undefined,
        purposes: [],
        selectedPurpose: null,
        disciplines: [],
        selectedDisciplines: [],
        keywords: [],
        selectedKeywords: [],
        senderNumber: "",
        attachments: [],
        fileUploadIsInProgress: false,
        failedUploadAttachmentNames: [],
        specSectionName: "",
        submittalNumber: "",
        revisionNumber: "",
        isRevisionNumberEnabled: false,
        numberEntryMode: null,
        reminderDays: undefined,
        isCloudProject: true,
        shouldClearSpecSection: false,
        isLoadingProjects: false,
        isSpecSectionRequired: true,
        shouldForwardSubmittal: false,
        shouldShowForwardSubmittalOptions: false,
        selectedSpecSection: undefined,
        selectedItemNumber: "",
        selectedRevisionNumber: "",
        emailRecipients: {
            to: [],
            cc: [],
        },
        forwardSubmittalBackButtonClicked: false,
        projectsWithSuggestedToBeRendered: [],
        forwardSubmittalSelectedPurpose: null,
        forwardSubmittalPurposes: [],
        forwardEmailRecipients: {
            to: [],
            cc: [],
        },
        forwardDueDate: undefined,
        forwardRemarks: undefined,
        forwardReminderDays: undefined,
        shouldClearForwardValues: false,
        isLoadingPurposes: false,
        isForwardFormValid: false,
        areToAndCCSame: false,
        isDateInPast: false,
    };

    reminderMaxValue = 365;

    constructor(props: SubmittalComponentProps, context: SubmittalComponentState) {
        super(props, context);

        this.state = this.defaultState;
    }

    async componentDidMount(): Promise<void> {
        this.props.logger.info("SubmittalComponent mounted");
        this.props.onSetNavigationPage(AppPage.FileAsSubmittal);
        this.setState({ isLoading: true });
        await Promise.all([this.populateFormWithDefaults(), this.loadProjects()]);
        this.setState({ isLoading: false });
    }

    async componentDidUpdate(
        prevProps: Readonly<SubmittalComponentProps>,
        prevState: Readonly<SubmittalComponentState>
    ) {
        if (this.props.mailboxItem && prevProps.mailboxItem !== this.props.mailboxItem) {
            this.setState((state) => ({ ...this.defaultState, project: state.project, projects: state.projects }));

            await this.populateFormWithDefaults();
            this.setState({ isLoading: false });
        }
        if (prevState.project?.key !== this.state.project?.key) {
            this.toggleProjectTeamVisibility();
        }
        // the forwardSubmittalBackButtonClicked value is used as a flag in the onProjectSelected method to prevent reloading of project and keywords when changing between forward submittal and submittal views
        if (
            !this.state.shouldShowForwardSubmittalOptions &&
            this.state.shouldShowForwardSubmittalOptions !== prevState.shouldShowForwardSubmittalOptions
        ) {
            this.setState({ forwardSubmittalBackButtonClicked: false });
        }
    }

    private toggleProjectTeamVisibility() {
        const teamMemberDiv = document.querySelector(
            ".ms-CommandBar-secondaryCommand .ms-OverflowSet-item:nth-child(2)"
        );
        this.state.isCloudProject
            ? teamMemberDiv?.classList.remove("hideProjectTeam")
            : teamMemberDiv?.classList.add("hideProjectTeam");
    }

    private async populateFormWithDefaults() {
        this.props.logger.info("Populating submittal form with defaults");
        this.setState({ isLoading: true });

        const emailFrom = await this.props.officeWrapper.getSenderEmailAddress();
        const emailRecipients = this.props.officeWrapper
            .getCurrentEmailToRecipients()
            .map((recipient) => recipient.emailAddress)
            .filter((email) => !email.toLowerCase().endsWith("newforma.email"))
            .map((email) => ({ text: email }));

        const receivedDate = this.props.officeWrapper.getCurrentEmailDate();
        const subject = (await this.props.officeWrapper.getCurrentEmailSubject()).trim();
        const body = await this.props.msGraphApiService.getCurrentClearMessageBody(
            this.props.isFileTransferAndEditorSupported
        );
        const attachments = this.props.officeWrapper.getCurrentEmailAttachmentDetails();
        const attachmentsToUse = attachments.filter((attachment) => !attachment.isInline);
        const attachmentsNoCloud = attachmentsToUse.filter(
            (attachment) => attachment.attachmentType !== Office.MailboxEnums.AttachmentType.Cloud
        );
        const attachmentDetails = await this.props.msGraphApiService.getAttachmentDetails();
        const htmlBody = AttachmentDataHelpers.replaceCIDReferences(body, attachmentDetails);
        this.setState((state) => ({
            ...this.defaultState,
            project: state.project,
            subject: subject,
            description: this.props.isFileTransferAndEditorSupported ? htmlBody.trim() : body.trim(),
            to: emailRecipients,
            from: emailFrom ? [{ text: emailFrom }] : [],
            receivedDate: receivedDate,
            dueDate: this.props.dateHelpers.getDateWithOffset(receivedDate, 14),
            projects: state.projects,
            attachments: attachmentsNoCloud,
            isCloudProject: this.props.nrnServiceWrapper.isCloudProject(this.state.project?.key as string),
            isLoadingKeywords: state.isLoadingKeywords,
        }));
    }

    private async loadProjects(): Promise<void> {
        this.setState({ isLoadingProjects: true });
        try {
            const projectsResponse = await this.props.projectsService.getProjectsSupportingSubmittals();
            const projectsITag: ITagWithNixEnabled[] = projectsResponse.projects.map((project) => {
                const projectDisplay = project.number ? `${project.number} - ${project.name}` : project.name;
                return { key: project.nrn, name: projectDisplay, nixEnabled: project.nixEnabled };
            });
            this.setState({ projects: projectsITag });
        } catch (error) {
            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }
            this.setState({
                projects: [],
            });
            this.props.onShowToast(
                this.props.translate("SUBMITTALS.LOADING_PROJECTS_FAILED") as string,
                MessageBarType.severeWarning
            );
        } finally {
            this.setState({ isLoadingProjects: false });
        }
    }

    private onSubjectChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        this.setState({ subject: newValue || "" });
    }

    private onDescriptionChange(newValue?: string): void {
        this.setState({ description: newValue || "" });
    }

    private onOldEditorDescriptionChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void {
        this.setState({ description: newValue || "" });
    }

    private onInternalNotesChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void {
        this.setState({ internalNotes: newValue || "" });
    }

    private onFromChange(items?: IPersonaProps[]): void {
        this.setState({ from: items?.slice(-1) || [] });
    }

    private onToChange(items?: IPersonaProps[]): void {
        this.setState({ to: items || [] });
    }

    private onProjectSelected(project: ITagWithNixEnabled | null): ITagWithNixEnabled | null {
        if (this.state.forwardSubmittalBackButtonClicked && !this.state.shouldShowForwardSubmittalOptions) {
            return null;
        }
        this.clearKeywords();

        if (!project) {
            // set selected project to null and clear the values in forward submittal
            this.setState({
                project: null,
                shouldClearForwardValues: true,
                forwardDueDate: undefined,
                forwardRemarks: "",
                forwardSubmittalSelectedPurpose: null,
                forwardEmailRecipients: { to: [], cc: [] },
                forwardReminderDays: undefined,
            });
            return null;
        }

        const isCloudProject = this.props.nrnServiceWrapper.isCloudProject(project.key);
        const shouldForwardSubmittal = false;

        this.setState({ project, isCloudProject, shouldForwardSubmittal });
        // currently ignoring this promise because it was causing a delay in updating the ui with user's selection. code executes as expected
        if (project) {
            // tslint:disable-next-line
            this.getKeywords(project.key, project.name);
        }
        this.getProjectSettings(project.key, isCloudProject);

        return project;
    }

    private getProjectSettings(projectKey: string, isCloud: boolean) {
        // tslint:disable-next-line
        this.props.projectsService.getProjectSettings(projectKey).then((projectSettings) => {
            const forwardDays = projectSettings?.submittals.reviewResponseDueDateOffsetInDays;
            const forwardDaysType = projectSettings?.submittals.reviewResponseDueDateOffsetType;
            const todayDate = this.props.dateHelpers.TodayDate;
            let forwardDueDate;
            if (forwardDays) {
                forwardDueDate = this.props.dateHelpers.addDays(todayDate, forwardDays, forwardDaysType === "business");
            }

            const dueDate = this.getDueDate(
                projectSettings?.submittals.dueDateOffsetInDays,
                projectSettings?.submittals.dueDateOffsetType === "business"
            );

            if (forwardDueDate && dueDate && forwardDueDate > dueDate) {
                forwardDueDate = dueDate;
            }

            this.setState({
                dueDate,
                numberEntryMode: projectSettings?.submittals.numberEntryMode || null,
                isSpecSectionRequired:
                    isCloud || projectSettings?.submittals.numberEntryMode === NumberEntry.fromKeywordField,
                forwardDueDate,
            });
        });
    }

    private getDueDate(dateOffset: number | undefined, isBusiness?: boolean): Date | undefined {
        if (!this.state.receivedDate) {
            return undefined;
        }

        const offset = dateOffset === undefined ? 14 : dateOffset;
        // the below line fixes the bug for submittal dueDate not using calendar or business days - when switching to use this line, unit tests fail so work will need to be done there once bug is worked on
        // return this.props.dateHelpers.addDays(this.state.receivedDate, offset, isBusiness);
        return this.props.dateHelpers.getDateWithOffset(this.state.receivedDate, offset);
    }

    private clearKeywords(): void {
        this.setState({
            disciplines: [],
            keywords: [],
            purposes: [],
            selectedKeywords: [],
            selectedDisciplines: [],
        });
    }

    private onReceivedDateChanged(date: Date | undefined): void {
        this.setState({ receivedDate: date });
    }

    private onDueDateChanged(date: Date | undefined): void {
        this.setState({ dueDate: date });
    }

    private async getKeywords(projectNrn: string, projectName: string): Promise<void> {
        this.props.logger.info(`Loading keywords for selected project: ${projectName}`);
        this.setState({ isLoadingKeywords: true });

        try {
            const [purposesResponse, disciplinesResponse, keywordsResponse] = await Promise.all([
                this.props.projectsService.getProjectSubmittalPurposesKeywords(projectNrn),
                this.props.projectsService.getProjectKeywords(projectNrn, KeywordListType.SubmittalDiscipline),
                this.props.projectsService.getProjectKeywords(projectNrn, KeywordListType.SubmittalKeyword),
            ]);

            const purposes = ProjectHelper.sortKeywords(purposesResponse);
            const disciplines = this.sortKeywords(disciplinesResponse).map((discipline) => ({
                text: discipline.name,
                data: discipline,
            }));
            const keywords = this.sortKeywords(keywordsResponse).map((keyword) => ({
                text: keyword.name,
                data: keyword,
            }));

            this.setState({
                purposes: purposes,
                disciplines: disciplines,
                keywords: keywords,
            });

            if (purposes && purposes.length > 0) {
                this.setState({
                    selectedPurpose: purposes[0],
                });
            }
        } catch (error) {
            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }

            this.props.logger.error("SubmittalComponent Error loading keywords", error);

            this.clearKeywords();
            this.props.onShowToast(
                this.props.translate("SUBMITTALS.LOADING_KEYWORDS_FAILED") as string,
                MessageBarType.severeWarning
            );
        } finally {
            this.setState({ isLoadingKeywords: false });
        }
    }

    private sortKeywords(keywordsResponse: ProjectKeywordsResponse): ProjectKeyword[] {
        return keywordsResponse.items.sort((a, b) => a.displayOrder - b.displayOrder);
    }

    private onPurposeSelectionChange(option: DetailedKeyword | null): void {
        this.setState({ selectedPurpose: option });
    }

    private onDisciplinesChanged(items: IPersonaProps[]): void {
        this.setState({ selectedDisciplines: items });
    }

    private onKeywordsChanged(items: IPersonaProps[]): void {
        this.setState({ selectedKeywords: items });
    }

    private onSenderNumberChanged(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void {
        this.setState({ senderNumber: newValue || "" });
    }

    private async onFileSubmittal(): Promise<void> {
        this.props.logger.info("Submittal form submitted");
        this.setState({ isFiling: true });

        try {
            const selectedProject = this.state.project as ITagWithNixEnabled;
            const messageNrn = this.props.officeWrapper.getCurrentMessageNrn();
            const sourcePurpose: any = this.state.selectedPurpose;
            const allowedPurposeFields = ["name", "type"];
            const purpose = Object.keys(sourcePurpose)
                .filter((key) => allowedPurposeFields.includes(key))
                .reduce((obj, key) => {
                    return {
                        ...obj,
                        [key]: sourcePurpose[key],
                    };
                }, {} as DetailedKeyword);

            const forwardReviewDate = this.state.forwardDueDate
                ? this.props.dateHelpers.adjustTime(this.state.forwardDueDate)
                : null;

            const fsPurpose: any = this.state.forwardSubmittalSelectedPurpose;
            const forwardSubmittal: LogForwardSubmittalRequest = {
                version: "0",
                purpose: { name: fsPurpose?.name, type: fsPurpose?.type },
                via: "info-exchange",
                reviewDueDate: forwardReviewDate || undefined,
                remarks: this.state.forwardRemarks?.trim() || undefined,
                to: this.props.formValidationHelpers.mapPersonaPropsToContact(this.state.forwardEmailRecipients.to),
                cc: this.state.forwardEmailRecipients.cc
                    ? this.props.formValidationHelpers.mapPersonaPropsToContact(this.state.forwardEmailRecipients.cc)
                    : [],
                reminder:
                    !!this.state.forwardDueDate && !this.state.isCloudProject
                        ? this.state.forwardReminderDays
                        : undefined,
                specSection: this.state.specSectionName
                    ? { name: this.state.specSectionName, type: "generic" }
                    : undefined,
            };
            const submittal: LogSubmittalRequest = {
                subject: this.state.subject as string,
                purpose,
                description: this.state.description?.trim() || undefined,
                senderNumber: !!this.state.senderNumber ? this.state.senderNumber.trim() : undefined,
                internalNotes: !!this.state.internalNotes ? this.state.internalNotes.trim() : undefined,
                dueDate: (this.state.dueDate as Date).toISOString(),
                receivedDate: (this.state.receivedDate as Date).toISOString(),
                disciplines: this.props.formValidationHelpers.mapPersonaPropsToKeywords(this.state.selectedDisciplines),
                keywords: this.props.formValidationHelpers.mapPersonaPropsToKeywords(this.state.selectedKeywords),
                from: this.props.formValidationHelpers.mapPersonaPropsToContact(this.state.from)[0],
                to: this.props.formValidationHelpers.mapPersonaPropsToContact(this.state.to),
                specSection: this.state.specSectionName
                    ? { name: this.state.specSectionName, type: "generic" }
                    : undefined,
                number: this.state.submittalNumber.trim() || undefined,
                revisionNumber: this.state.revisionNumber?.trim() || undefined,
                autoRevisionNumber:
                    this.state.isRevisionNumberEnabled && !this.state.revisionNumber?.trim() ? true : undefined,
                via: this.props.submittalsApiService.getVia(selectedProject.key as string),
                reminder: !!this.state.dueDate && !this.state.isCloudProject ? this.state.reminderDays : undefined,
                forwardSubmittalData:
                    forwardSubmittal && this.props.isForwardSubmittalSupported && this.state.shouldForwardSubmittal
                        ? forwardSubmittal
                        : undefined,
            };

            if (selectedProject && this.props.mailboxItem) {
                const projectAsAny = this.state.project as any;
                this.props.analyticsManager.recordSmartFilingEvents(
                    projectAsAny.suggestedProject,
                    projectAsAny.suggestionIndex
                );
                await this.props.smartFilingManager.addToFiledHistory(
                    selectedProject,
                    this.props.mailboxItem.conversationId,
                    this.props.mailboxItem.sender.emailAddress
                );
            }

            if (!!submittal.number) {
                this.props.analyticsManager.recordEvent(
                    AnalyticsCategoryType.UserActions,
                    AnalyticsActionType.SubmittalNumber
                );
            }
            await this.props.submittalsApiService.logEmailAsSubmittal(
                selectedProject.key,
                messageNrn,
                submittal,
                this.state.attachments,
                this.fileUploadCallback.bind(this),
                this.props.isFileTransferAndEditorSupported
            );
            if (
                this.props.isForwardSubmittalSupported &&
                this.state.shouldForwardSubmittal &&
                submittal.forwardSubmittalData
            ) {
                this.shouldGoBackToSubmittal();
                this.props.showMultipleToasts([
                    {
                        message: this.props.translate("SUBMITTALS.FORWARD_SUBMITTAL.CREATE_SUCCESSFUL") as string,
                        type: MessageBarType.success,
                    },
                ]);
            } else {
                this.props.onShowToast(
                    this.props.translate("SUBMITTALS.CREATE_SUCCESSFUL") as string,
                    MessageBarType.success
                );
            }
            this.setState({
                specSectionName: "",
                revisionNumber: "",
                isRevisionNumberEnabled: false,
                submittalNumber: "",
                senderNumber: "",
                internalNotes: "",
                selectedDisciplines: [],
                selectedKeywords: [],
                shouldClearSpecSection: true,
            });
        } catch (error) {
            this.shouldGoBackToSubmittal();
            this.handleApiError(error);
        } finally {
            this.props.logger.info("Logging a submittal complete");
            this.setState({ shouldClearSpecSection: false });
        }

        this.props.analyticsManager.recordEvent(
            AnalyticsCategoryType.UserActions,
            AnalyticsActionType.FileEmailAsSubmittal
        );

        this.setState({ isFiling: false });
    }

    private isFormValid(): boolean {
        const forwardSubmittalValidations =
            this.state.shouldShowForwardSubmittalOptions && (this.state.areToAndCCSame || this.state.isDateInPast);

        const isSubmittalFormValid = !!(
            this.state.project &&
            this.state.subject &&
            this.props.formValidationHelpers.areAssigneesValid(this.state.from, true) &&
            this.props.formValidationHelpers.areAssigneesValid(this.state.to, true) &&
            this.state.selectedPurpose !== null &&
            this.state.receivedDate &&
            this.state.dueDate &&
            !this.state.isLoading &&
            !this.state.isFiling &&
            !this.state.isLoadingPurposes &&
            this.isSpecSectionSet() &&
            this.isSubmittalNumberSet() &&
            !forwardSubmittalValidations
        );

        const isForwardFormValid = !this.state.shouldShowForwardSubmittalOptions || this.state.isForwardFormValid;
        return isSubmittalFormValid && isForwardFormValid;
    }

    private isSpecSectionSet(): boolean {
        return this.state.numberEntryMode === NumberEntry.fromKeywordField || this.state.isCloudProject
            ? !!this.state.specSectionName
            : true;
    }

    private isSubmittalNumberSet(): boolean {
        return this.state.numberEntryMode === NumberEntry.manual ? !!this.state.submittalNumber : true;
    }

    private handleApiError(error: any): void {
        this.props.logger.error("SubmittalComponent API error", error);

        if (ExpiredSessionError.isInstanceOf(error)) {
            this.props.onExpiredSession();
            return;
        }

        if (error.status === 405) {
            this.props.onShowToast(
                this.props.translate("SUBMITTALS.ACTIVITY_CENTER_DISABLED_ERROR") as string,
                MessageBarType.severeWarning
            );
            return;
        }

        if (error.level !== undefined && error.level === ApiRequestErrorLevel.WARNING) {
            this.props.onShowToast(this.props.translate(error.messageToDisplay) as string, MessageBarType.warning);
            return;
        }

        if (error.level !== undefined && error.level === ApiRequestErrorLevel.ERROR) {
            const failedAttachmentNames = this.state.failedUploadAttachmentNames;
            if (failedAttachmentNames.length) {
                const messageToDisplay = (this.props.translate(error.messageToDisplay) as string)
                    .replace("[[attachment-names]]", failedAttachmentNames.join("\n"))
                    .replace("[[failed-attachment-count]]", failedAttachmentNames.length.toString());
                this.props.onShowToast(messageToDisplay, MessageBarType.severeWarning);
                return;
            }
            this.props.onShowToast(
                this.props.translate(error.messageToDisplay) as string,
                MessageBarType.severeWarning
            );
            return;
        }

        this.props.onShowToast(
            this.props.isForwardSubmittalSupported && this.state.shouldForwardSubmittal
                ? (this.props.translate("SUBMITTALS.FORWARD_SUBMITTAL.CREATE_FAILED") as string)
                : (this.props.translate("SUBMITTALS.CREATE_FAILED") as string),
            MessageBarType.severeWarning
        );
    }

    fileUploadCallback(isInProgress: boolean, failedIds: string[]) {
        const failedAttachmentNames = !isInProgress ? this.getFailedAttachmentNames(failedIds) : [];
        this.setState({
            fileUploadIsInProgress: isInProgress,
            failedUploadAttachmentNames: failedAttachmentNames,
        });
    }

    private getFailedAttachmentNames(failedIds: string[]): string[] {
        return failedIds.length > 0
            ? this.state.attachments.filter((x) => failedIds.includes(x.id)).map((x) => x.name)
            : [];
    }

    updateSelectedSpecSection(item: ITagWithNixEnabled | undefined) {
        this.setState({
            selectedSpecSection: item,
        });
    }

    updateSubmittalNumber(submittalNumber: string) {
        this.setState({
            selectedItemNumber: submittalNumber,
        });
    }

    updateRevisionNumber(revisionNumber: string) {
        this.setState({
            selectedRevisionNumber: revisionNumber,
        });
    }

    private onSpecSectionChange(
        specSectionName: string,
        itemNumber: string,
        revisionNumber: string,
        isRevisionNumberEnabled: boolean
    ): void {
        this.setState({ specSectionName, submittalNumber: itemNumber, revisionNumber, isRevisionNumberEnabled });
    }

    private onSpecSectionError(error: any): void {
        this.props.logger.error("SubmittalComponent onSpecSectionError", error);

        if (ExpiredSessionError.isInstanceOf(error)) {
            this.props.onExpiredSession();
            return;
        }

        this.props.onShowToast(
            this.props.translate("SUBMITTALS.SPEC_SECTION.LOADING_FAILED") as string,
            MessageBarType.severeWarning
        );
    }

    private onAttachmentsUpdated(files: AttachmentItem[]): void {
        this.setState({
            attachments: files,
        });
        document.querySelector(".newforma-submittalComponent")?.scrollTo({
            top: document.querySelector(".newforma-submittalComponent")?.scrollHeight,
            behavior: "auto",
        });
    }

    private onReminderChange(value: number | undefined): void {
        this.setState({ reminderDays: value });
    }

    private async onRefresh(): Promise<void> {
        this.setState({ isLoading: true });
        await this.props.invalidateCacheService.invalidateCache(ProjectsCacheKeys.submittalsCacheName);
        await this.loadProjects();
        this.setState({ isLoading: false });
    }

    updateForwardSubmittalCheckbox() {
        this.setState({ shouldForwardSubmittal: !this.state.shouldForwardSubmittal });
    }

    shouldGoBackToSubmittal() {
        this.setState({ shouldShowForwardSubmittalOptions: false });
        this.props.logger.info("Changing to submittal view");
    }

    moveToForwardSubmittal() {
        this.setState({
            shouldShowForwardSubmittalOptions: true,
            emailRecipients: { to: this.state.to, from: this.state.from },
        });
        this.props.logger.info("Changing to forward submittal view");
    }

    updateProjectsToBeRendered(projects: ProjectITag[]) {
        this.setState({ projectsWithSuggestedToBeRendered: projects });
    }

    updateForwardPurposes(option: ProjectKeyword | null, purposes: ProjectKeyword[]): void {
        this.setState({ forwardSubmittalSelectedPurpose: option, forwardSubmittalPurposes: purposes });
    }

    updatePurposesLoadingState(isLoading: boolean): void {
        this.setState({ isLoadingPurposes: isLoading });
    }

    updateForwardEmailRecipients(emails: EmailRecipients): void {
        this.setState({ forwardEmailRecipients: emails });
    }

    updateForwardDueDate(date: Date | undefined): void {
        this.setState({ forwardDueDate: date });
    }

    updateForwardRemarks(remarks: string | undefined): void {
        this.setState({ forwardRemarks: remarks });
    }

    updateForwardReminder(days: number | undefined): void {
        this.setState({ forwardReminderDays: days });
    }

    onForwardSubmittalBackButtonClicked() {
        this.setState({ shouldClearForwardValues: false });
    }

    handleForwardSubmittalToast(result: LogForwardSubmittalResponse) {
        if (result.forwardSuccess) {
            this.props.showMultipleToasts([
                {
                    message: this.props.translate("SUBMITTALS.FORWARD_SUBMITTAL.CREATE_SUCCESSFUL") as string,
                    type: MessageBarType.success,
                },
            ]);
        } else {
            this.props.showMultipleToasts([
                {
                    message: this.props.translate("SUBMITTALS.FORWARD_SUBMITTAL.SUBMITTAL_FILED_SUCCESS") as string,
                    type: MessageBarType.success,
                },
                {
                    message: result.forwardError
                        ? result.forwardError
                        : (this.props.translate("SUBMITTALS.FORWARD_SUBMITTAL.FORWARD_SUBMITTAL_FAILED") as string),
                    type: MessageBarType.severeWarning,
                },
            ]);
        }
    }

    private onCCAndToComparison(areToAndCCSame: boolean): void {
        this.setState({ areToAndCCSame });
    }

    private onDateCheck(isDateInPast: boolean): void {
        this.setState({ isDateInPast });
    }

    private handleForwardFormValidation = (isValid: boolean): void => {
        this.setState({ isForwardFormValid: isValid });
    };

    private isForwardDisabled = (): boolean => {
        const project = this.state.project;
        if (!project || project.nixEnabled) {
            return false;
        }
        return true;
    };

    render(): JSX.Element {
        return (
            <div id="submittalComponent" className="newforma-submittalComponent">
                <div className="newforma-submittalComponentForm">
                    {this.state.shouldShowForwardSubmittalOptions ? (
                        <ForwardSubmittal
                            shouldShowSubmittal={this.shouldGoBackToSubmittal.bind(this)}
                            theme={this.props.theme}
                            logger={this.props.logger}
                            officeWrapper={this.props.officeWrapper}
                            formValidationHelpers={this.props.formValidationHelpers}
                            projectsService={this.props.projectsService}
                            onShowToast={this.props.onShowToast}
                            mailboxItem={this.props.mailboxItem}
                            selectedProject={this.state.project}
                            onExpiredSession={this.props.onExpiredSession}
                            updatePurposes={this.updateForwardPurposes.bind(this)}
                            updateLoadingState={this.updatePurposesLoadingState.bind(this)}
                            priorSelectedPurpose={this.state.forwardSubmittalSelectedPurpose}
                            priorSelectedPurposes={this.state.forwardSubmittalPurposes}
                            updateEmails={this.updateForwardEmailRecipients.bind(this)}
                            priorEmails={this.state.forwardEmailRecipients}
                            updateForwardDueDate={this.updateForwardDueDate.bind(this)}
                            forwardDueDate={this.state.forwardDueDate}
                            updateRemarks={this.updateForwardRemarks.bind(this)}
                            forwardRemarks={this.state.forwardRemarks}
                            updateReminder={this.updateForwardReminder.bind(this)}
                            forwardReminder={this.state.forwardReminderDays}
                            updateClearValue={this.onForwardSubmittalBackButtonClicked.bind(this)}
                            shouldClearValues={this.state.shouldClearForwardValues}
                            configService={this.props.configService}
                            onFormValidationChange={this.handleForwardFormValidation}
                            isFileTransferAndEditorSupported={this.props.isFileTransferAndEditorSupported}
                            isFiling={this.state.isFiling}
                            onEmailCheck={this.onCCAndToComparison.bind(this)}
                            onDateCheck={this.onDateCheck.bind(this)}
                        />
                    ) : (
                        <>
                            <SuggestedProjectPickerComponent
                                logger={this.props.logger}
                                className="newforma-submittalSpacing"
                                projects={this.state.projects}
                                onProjectSelected={this.onProjectSelected.bind(this)}
                                disabled={
                                    this.state.isLoadingProjects ||
                                    this.state.isLoading ||
                                    this.state.isFiling ||
                                    this.state.fileUploadIsInProgress
                                }
                                smartFilingManager={this.props.smartFilingManager}
                                isLoadingProjects={this.state.isLoading}
                                mailboxItem={this.props.mailboxItem}
                                onRefresh={this.onRefresh.bind(this)}
                                theme={this.props.theme}
                                myProjects={this.state.projects}
                                selectedProject={this.state.project}
                                updateProjectsToBeRendered={this.updateProjectsToBeRendered.bind(this)}
                                projectsToBeRendered={this.state.projectsWithSuggestedToBeRendered}
                            />
                            <Label required={true}>{this.props.translate("SUBMITTALS.SUBJECT_LABEL") as string}</Label>
                            <TextField
                                className="newforma-submittalSpacing"
                                id="submittal-subject"
                                value={this.state.subject}
                                onChange={this.onSubjectChange.bind(this)}
                                disabled={this.state.isFiling || this.state.isLoading}
                                maxLength={256}
                            />
                            <SpecSectionComponent
                                className={`newforma-submittalSpacing ${
                                    this.state.isLoading || !this.state.project ? "disabledStyle" : ""
                                }`}
                                disabled={this.state.isFiling || this.state.isLoading || !this.state.project}
                                onChange={this.onSpecSectionChange.bind(this)}
                                onError={this.onSpecSectionError.bind(this)}
                                projectsService={this.props.projectsService}
                                project={this.state.project}
                                showSpecNumber={!this.state.isCloudProject}
                                keywordListType={KeywordListType.SubmittalSpecSection}
                                mailboxItem={this.props.mailboxItem}
                                required={this.state.isSpecSectionRequired}
                                numberEntryMode={this.state.numberEntryMode}
                                formValidationHelpers={this.props.formValidationHelpers}
                                hideRevision={!this.state.isCloudProject}
                                clearSpecSection={this.state.shouldClearSpecSection}
                                onSpecChange={this.updateSelectedSpecSection.bind(this)}
                                onSubmittalNumberChange={this.updateSubmittalNumber.bind(this)}
                                onRevisionNumberChange={this.updateRevisionNumber.bind(this)}
                                selectedSpecSection={this.state.selectedSpecSection}
                                selectedItemNumber={this.state.selectedItemNumber}
                                selectedRevisionNumber={this.state.revisionNumber}
                            />
                            <div className="newforma-submittalDatepickers newforma-submittalSpacing">
                                <BoundDateRangePickerComponent
                                    startDate={this.state.receivedDate}
                                    endDate={this.state.dueDate}
                                    startDateLabel={this.props.translate("SUBMITTALS.RECEIVED_DATE") as string}
                                    endDateLabel={this.props.translate("SUBMITTALS.DUE_DATE") as string}
                                    onStartDateChange={this.onReceivedDateChanged.bind(this)}
                                    onEndDateChange={this.onDueDateChanged.bind(this)}
                                    disabled={this.state.isFiling || this.state.isLoading}
                                    startDateRequired={true}
                                    endDateRequired={true}
                                    showClearDateButtonStart={false}
                                    showClearDateButtonEnd={false}
                                />
                            </div>
                            {!this.state.isCloudProject ? (
                                <ReminderComponent
                                    onChange={this.onReminderChange.bind(this)}
                                    className="newforma-formSpacing"
                                    max={this.reminderMaxValue}
                                    disabled={this.state.dueDate === undefined}
                                    value={this.state.reminderDays}
                                    isFiling={this.state.isFiling}
                                />
                            ) : null}
                            <div className="newforma-flexRow newforma-submittalSpacing">
                                <KeywordsDropdown
                                    className="newforma-submittalPurposeDropdown"
                                    id="purposePicker"
                                    options={this.state.purposes}
                                    label={this.props.translate("SUBMITTALS.PURPOSE_PICKER_LABEL") as string}
                                    placeholder={
                                        this.props.translate("SUBMITTALS.PURPOSE_PICKER_PLACEHOLDER") as string
                                    }
                                    disabled={
                                        !this.state.project ||
                                        this.state.isLoadingKeywords ||
                                        this.state.isFiling ||
                                        !this.state.purposes.length
                                    }
                                    isLoading={this.state.isLoadingKeywords}
                                    isProjectSelected={!!this.state.project}
                                    required={true}
                                    onSelectionChange={this.onPurposeSelectionChange.bind(this)}
                                    selectFirstOption={false}
                                    theme={this.props.theme}
                                    selectedPurposeKey={
                                        this.state.selectedPurpose ? this.state.selectedPurpose.displayOrder : null
                                    }
                                />
                                <div className="newforma-senderNumber">
                                    <Label required={false}>
                                        {this.props.translate("SUBMITTALS.SENDER_NUMBER_LABEL") as string}
                                    </Label>
                                    <TextField
                                        id="submittal-senderNumber"
                                        value={this.state.senderNumber}
                                        onChange={this.onSenderNumberChanged.bind(this)}
                                        disabled={this.state.isFiling || this.state.isLoading}
                                        maxLength={256}
                                    />
                                </div>
                            </div>

                            <FromAndToComponent
                                key="submittalComponent"
                                className={"newforma-formSpacing"}
                                isFromRequired={true}
                                isToRequired={true}
                                isCcRequired={false}
                                includeCc={false}
                                officeWrapper={this.props.officeWrapper}
                                disabled={this.state.isFiling || this.state.isLoading}
                                formValidationHelpers={this.props.formValidationHelpers}
                                mailboxItem={this.props.mailboxItem}
                                onFromChange={this.onFromChange.bind(this)}
                                onToChange={this.onToChange.bind(this)}
                                logger={this.props.logger}
                                projectsService={this.props.projectsService}
                                disableOnError={true}
                                onError={this.props.onShowToast}
                                project={this.state.project}
                                shouldHideFrom={false}
                                isForwardInProgress={this.state.shouldForwardSubmittal}
                                EmailRecipients={this.state.emailRecipients}
                            />
                            <Label>{this.props.translate("SUBMITTALS.DESCRIPTION_LABEL") as string}</Label>
                            {this.props.isFileTransferAndEditorSupported ? (
                                <HTMLEditor
                                    value={this.state.description}
                                    onRemarksUpdate={this.onDescriptionChange.bind(this)}
                                    configService={this.props.configService}
                                    isFiling={this.state.isFiling}
                                />
                            ) : (
                                <TextField
                                    className="newforma-submittalSpacing"
                                    id="submittal-description"
                                    multiline
                                    resizable={true}
                                    value={this.state.description}
                                    onChange={this.onOldEditorDescriptionChange.bind(this)}
                                    disabled={this.state.isFiling || this.state.isLoading}
                                    rows={5}
                                    maxLength={65000}
                                />
                            )}
                            <Label>{this.props.translate("SUBMITTALS.INTERNAL_NOTES_LABEL") as string}</Label>
                            <TextField
                                className="newforma-submittalSpacing newforma-submittalInternalNotes"
                                id="submittal-internalNotes"
                                multiline={this.state.internalNotes ? this.state.internalNotes.length > 50 : false}
                                resizable={true}
                                value={this.state.internalNotes}
                                onChange={this.onInternalNotesChange.bind(this)}
                                disabled={this.state.isFiling || this.state.isLoading}
                                autoAdjustHeight={true}
                                maxLength={65000}
                            />
                            <ChipPickerComponent
                                className="newforma-submittalSpacing newforma-submittalDisciplines"
                                required={false}
                                disabled={
                                    this.state.isFiling ||
                                    this.state.isLoading ||
                                    this.state.isLoadingKeywords ||
                                    !this.state.project
                                }
                                items={this.state.disciplines}
                                selectedItems={this.state.selectedDisciplines}
                                onSelectedItemsChanged={this.onDisciplinesChanged.bind(this)}
                                label={this.props.translate("SUBMITTALS.DISCIPLINES_LABEL") as string}
                                allowCustomInput={false}
                            />
                            <ChipPickerComponent
                                className="newforma-submittalSpacing newforma-submittalKeywords"
                                required={false}
                                disabled={
                                    this.state.isFiling ||
                                    this.state.isLoading ||
                                    this.state.isLoadingKeywords ||
                                    !this.state.project
                                }
                                items={this.state.keywords}
                                selectedItems={this.state.selectedKeywords}
                                onSelectedItemsChanged={this.onKeywordsChanged.bind(this)}
                                label={this.props.translate("SUBMITTALS.KEYWORDS_LABEL") as string}
                                allowCustomInput={true}
                            />
                            <AttachmentsComponent
                                attachments={this.state.attachments}
                                disabled={this.state.isFiling}
                                allowFileUploads={true}
                                onAttachmentsUpdated={this.onAttachmentsUpdated.bind(this)}
                                isCloudProject={this.state.isCloudProject}
                                logger={this.props.logger}
                                isFilePathInAttachmentsSupported={this.props.isFilePathInAttachmentsSupported}
                                isFileTransferAndEditorSupported={this.props.isFileTransferAndEditorSupported}
                                classNameForScroll=".newforma-submittalComponent"
                            />
                            {this.props.isForwardSubmittalSupported ? (
                                <ForwardCheckbox
                                    label={this.props.translate("SUBMITTALS.FORWARD_SUBMITTAL.FORWARD") as string}
                                    subtext={
                                        this.props.translate("SUBMITTALS.FORWARD_SUBMITTAL.FORWARD_SUBTEXT") as string
                                    }
                                    shouldForward={this.updateForwardSubmittalCheckbox.bind(this)}
                                    checked={this.state.shouldForwardSubmittal}
                                    isFiling={this.state.isFiling || this.isForwardDisabled()}
                                    showDisableMessage={this.isForwardDisabled()}
                                    theme={this.props.theme}
                                    showTeachingBubble={this.props.showForwardSubmittalTeachingBubble}
                                    teachingBubbleType={TeachingBubblesTypes.forwardReviewSubmittal}
                                    onDismissTeachingBubble={this.props.onDismissTeachingBubble}
                                />
                            ) : null}
                        </>
                    )}
                </div>
                {this.state.fileUploadIsInProgress ? (
                    <ProgressIndicator
                        label={this.props.translate("SHARED.FILE_UPLOAD_PROGRESS_LABEL") as string}
                        className="newforma-progressIndicator"
                        styles={{
                            itemName: ".ms-label",
                        }}
                    />
                ) : null}
                <div id="footer" key="footer" className="newforma-footer">
                    <DefaultButton
                        key="fileAsSubmittalSubmitButton"
                        className="newforma-footerButton"
                        id="fileSubmittalButton"
                        primary={true}
                        onClick={
                            this.state.shouldForwardSubmittal
                                ? this.state.shouldShowForwardSubmittalOptions
                                    ? this.onFileSubmittal.bind(this)
                                    : () => this.moveToForwardSubmittal()
                                : this.onFileSubmittal.bind(this)
                        }
                        text={
                            this.state.shouldShowForwardSubmittalOptions
                                ? (this.props.translate(
                                      "SUBMITTALS.FORWARD_SUBMITTAL.SUBMIT_BUTTON"
                                  ) as string).toLocaleUpperCase()
                                : this.state.shouldForwardSubmittal
                                ? (this.props.translate(
                                      "SUBMITTALS.FORWARD_SUBMITTAL.CONTINUE_BUTTON"
                                  ) as string).toLocaleUpperCase()
                                : (this.props.translate("SUBMITTALS.SUBMIT_BUTTON") as string).toLocaleUpperCase()
                        }
                        disabled={!this.isFormValid()}
                    />
                </div>
            </div>
        );
    }
}

export default withLocalize(SubmittalComponent);
