import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import * as React from "react";
import { AppPage } from "../shared/navigationHeader/NavigationHeaderComponent";
import {
    DefaultButton,
    IPersonaProps,
    ITag,
    Label,
    MessageBarType,
    ProgressIndicator,
    TextField,
} from "office-ui-fabric-react";
import { DetailedKeyword, KeywordListType } from "../../models/ProjectKeywordsResponse";
import { MailboxItem, OfficeWrapper } from "../../services/OfficeWrapper";
import { ContactRequest } from "../../models/ContactRequest";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../../services/AnalyticsManager";
import TranslatedDatePickerComponent from "../shared/translatedDatePicker/TranslatedDatePickerComponent";
import { ApiRequestErrorLevel } from "../../models/ApiRequestErrorWithMessage";
import { ActionItemApiService } from "../../services/NewformaApi/ActionItemApiService";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import "./ActionItemComponent.less";
import AssigneeComponent from "../shared/assignee/AssigneeComponent";
import { FormValidationHelpers } from "../../helpers/FormValidationHelpers";
import { Logger } from "../../services/Logger";
import { IProjectsService } from "../../services/NewformaApi/IProjectsService";
import { ConfigurationsApiService } from "../../services/NewformaApi/ConfigurationsApiService";
import AttachmentsComponent from "../shared/attachments/AttachmentsComponent";
import SuggestedProjectPickerComponent from "../shared/suggestedProjectPicker/SuggestedProjectPickerComponent";
import { SmartFilingManager } from "../../services/SmartFiling/SmartFilingManager";
import AttachmentDetails = Office.AttachmentDetails;
import { ElementVisibility } from "../../models/shared/ElementVisibility";
import KeywordsDropdown from "../shared/keywordsDropdown/KeywordsDropdown";
import { MsGraphApiService } from "../../services/MsGraphApiService";
import { NrnServiceWrapper } from "../../services/NrnServiceWrapper";
import { ProjectHelper } from "../../helpers/ProjectHelper";
import { ProjectsCacheKeys } from "../../models/StorageKeys";
import { InvalidateCacheService } from "../../services/NewformaApi/InvalidateCacheService";
import { AttachmentItem } from "../../models/shared/AttachmentList";
import HTMLEditor from "../shared/editor/Editor";
import { ConfigurationService } from "../../services/ConfigurationService";
import { AttachmentDataHelpers } from "../../helpers/AttachmentDataHelpers";

export interface ActionItemComponentProps extends LocalizeContextProps {
    officeWrapper: OfficeWrapper;
    analyticsManager: AnalyticsManager;
    actionItemApiService: ActionItemApiService;
    onExpiredSession: () => void;
    formValidationHelpers: FormValidationHelpers;
    logger: Logger;
    projectsService: IProjectsService;
    configurationService: ConfigurationsApiService;
    smartFilingManager: SmartFilingManager;
    onSetNavigationPage: (page: AppPage) => void;
    onShowToast: (message: string | null, type: MessageBarType) => void;
    mailboxItem: MailboxItem | null;
    msGraphApiService: MsGraphApiService;
    nrnServiceWrapper: NrnServiceWrapper;
    theme: string;
    invalidateCacheService: InvalidateCacheService;
    isFilePathInAttachmentsSupported?: boolean;
    configService: ConfigurationService;
    isFileTransferAndEditorSupported: boolean;
}

export interface ActionItemComponentState {
    projects: ITag[];
    keywords: DetailedKeyword[];
    isLoadingProjects: boolean;
    isLoadingKeywords: boolean;
    isFilingEmail: boolean;
    selectedProject: ITag | null;
    selectedKeyword: DetailedKeyword | null;
    name: string;
    description: string;
    date: Date | undefined;
    assignees: IPersonaProps[];
    attachments: AttachmentItem[];
    fileUploadIsInProgress: boolean;
    failedUploadAttachmentNames: string[];
}

class ActionItemComponent extends React.Component<ActionItemComponentProps, ActionItemComponentState> {
    defaultState: ActionItemComponentState = {
        assignees: [],
        projects: [],
        keywords: [],
        isLoadingProjects: true,
        isLoadingKeywords: false,
        isFilingEmail: false,
        selectedProject: null,
        selectedKeyword: null,
        name: "",
        description: "",
        date: undefined,
        attachments: [],
        fileUploadIsInProgress: false,
        failedUploadAttachmentNames: [],
    };

    constructor(props: ActionItemComponentProps, context: ActionItemComponentState) {
        super(props, context);

        this.state = this.defaultState;
    }

    async componentDidMount(): Promise<void> {
        this.props.logger.info("ActionItemComponent mounted");
        this.props.onSetNavigationPage(AppPage.FileAsActionItem);
        await Promise.all([this.populateDefaults(), this.loadProjects()]);
    }

    async componentDidUpdate(
        prevProps: Readonly<ActionItemComponentProps>,
        prevState: Readonly<ActionItemComponentState>
    ) {
        if (this.props.mailboxItem && prevProps.mailboxItem !== this.props.mailboxItem) {
            this.setState((state) => ({
                ...this.defaultState,
                projects: state.projects,
                selectedProject: state.selectedProject,
            }));
            await this.populateDefaults();
            this.setState({ isLoadingProjects: false });
        }
        if (prevState.selectedProject?.key !== this.state.selectedProject?.key) {
            this.toggleProjectTeamVisibility();
        }
    }

    private toggleProjectTeamVisibility() {
        const isCloudProject = this.props.nrnServiceWrapper.isCloudProject(this.state.selectedProject?.key as string);
        const teamMemberDiv = document.querySelector(
            ".ms-CommandBar-secondaryCommand .ms-OverflowSet-item:nth-child(2)"
        );
        isCloudProject
            ? teamMemberDiv?.classList.remove("hideProjectTeam")
            : teamMemberDiv?.classList.add("hideProjectTeam");
    }

    private async populateDefaults(): Promise<void> {
        const emailSubject = await this.props.officeWrapper.getCurrentEmailSubject();
        const currentClearedMessage = await this.props.msGraphApiService.getCurrentClearMessageBody(
            this.props.isFileTransferAndEditorSupported
        );
        const attachments = this.props.officeWrapper.getCurrentEmailAttachmentDetails();
        const attachmentsToUse = attachments.filter((attachment) => !attachment.isInline);
        const attachmentsNoCloud = attachmentsToUse.filter((attachment) => {
            return attachment.attachmentType !== Office.MailboxEnums.AttachmentType.Cloud;
        });
        const attachmentDetails = await this.props.msGraphApiService.getAttachmentDetails();
        const htmlBody = AttachmentDataHelpers.replaceCIDReferences(currentClearedMessage, attachmentDetails);
        this.setState({
            name: emailSubject,
            description: this.props.isFileTransferAndEditorSupported ? htmlBody.trim() : currentClearedMessage.trim(),
            attachments: attachmentsNoCloud,
        });
    }

    private async loadProjects(): Promise<void> {
        this.setState({ isLoadingProjects: true });
        try {
            const projectsResponse = await this.props.projectsService.getProjectsSupportingActionItems();
            const projectsITag: ITag[] = projectsResponse.projects.map((project) => {
                const projectDisplay = project.number ? `${project.number} - ${project.name}` : project.name;
                return { key: project.nrn, name: projectDisplay };
            });
            this.setState({ projects: projectsITag });
        } catch (error) {
            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
            }
            this.setState({
                projects: [],
            });
            this.props.onShowToast(
                this.props.translate("ACTION_ITEM.LOADING_PROJECTS_FAILED") as string,
                MessageBarType.severeWarning
            );
        } finally {
            this.setState({ isLoadingProjects: false, assignees: [] });
        }
    }

    private async getKeywords(projectNrn: string): Promise<void> {
        this.setState({ isLoadingKeywords: true });
        try {
            const keywordsResponse = await this.props.projectsService.getProjectKeywords(
                projectNrn,
                KeywordListType.ActionItemType
            );
            const sortedKeywords = ProjectHelper.sortKeywords(keywordsResponse);
            this.setState({ keywords: sortedKeywords });
        } catch {
            this.setState({
                keywords: [],
                selectedKeyword: null,
            });
            this.props.onShowToast(
                this.props.translate("ACTION_ITEM.LOADING_KEYWORDS_FAILED") as string,
                MessageBarType.severeWarning
            );
        } finally {
            this.setState({ isLoadingKeywords: false });
        }
    }

    private async onFileActionItem(): Promise<void> {
        this.setState({ failedUploadAttachmentNames: [] });

        if (!this.state.selectedProject || !this.state.name) {
            this.props.onShowToast(
                this.props.translate("ACTION_ITEM.VALIDATION_ERROR") as string,
                MessageBarType.severeWarning
            );
            return;
        }

        const isFormValid = this.isFormValid();
        if (!isFormValid) {
            return;
        }
        const assignedTo: ContactRequest[] = this.getAssignees();
        const keyword = this.state.selectedKeyword || undefined;
        const messageNrn = this.props.officeWrapper.getCurrentMessageNrn();
        this.setState({ isFilingEmail: true });
        try {
            if (this.state.selectedProject && this.props.mailboxItem) {
                const projectAsAny = this.state.selectedProject as any;
                this.props.analyticsManager.recordSmartFilingEvents(
                    projectAsAny.suggestedProject,
                    projectAsAny.suggestionIndex
                );
                await this.props.smartFilingManager.addToFiledHistory(
                    this.state.selectedProject,
                    this.props.mailboxItem.conversationId,
                    this.props.mailboxItem.sender.emailAddress
                );
            }
            await this.props.actionItemApiService.createCompleteActionItem(
                messageNrn,
                this.state.selectedProject.key,
                this.state.name,
                this.fileUploadCallback.bind(this),
                this.state.date ? this.state.date.toISOString() : undefined,
                0,
                this.state.description,
                keyword,
                assignedTo,
                undefined,
                this.state.attachments.filter(
                    (att: AttachmentItem): att is AttachmentDetails => !!(att as AttachmentDetails).attachmentType
                )
            );
            this.props.onShowToast(
                this.props.translate("ACTION_ITEM.CREATE_SUCCESSFUL") as string,
                MessageBarType.success
            );
        } catch (error) {
            this.handleApiError(error);
        }
        this.props.analyticsManager.recordEvent(
            AnalyticsCategoryType.UserActions,
            AnalyticsActionType.FileEmailAsActionItem
        );
        this.setState({ isFilingEmail: false });
    }

    private handleApiError(error: any): void {
        this.props.logger.error("ActionItemComponent API error", error);
        if (ExpiredSessionError.isInstanceOf(error)) {
            this.props.onExpiredSession();
        }
        if (error.level !== undefined && error.level === ApiRequestErrorLevel.WARNING) {
            this.props.onShowToast(this.props.translate(error.messageToDisplay) as string, MessageBarType.warning);
        }
        if (error.level !== undefined && error.level === ApiRequestErrorLevel.ERROR) {
            const failedAttachmentNames = this.state.failedUploadAttachmentNames;
            const messageToDisplay = (this.props.translate(error.messageToDisplay) as string)
                .replace("[[attachment-names]]", failedAttachmentNames.join("\n"))
                .replace("[[failed-attachment-count]]", failedAttachmentNames.length.toString());
            this.props.onShowToast(messageToDisplay, MessageBarType.severeWarning);
        }
        if (error.level === undefined) {
            this.props.onShowToast(
                this.props.translate("ACTION_ITEM.CREATE_FAILED") as string,
                MessageBarType.severeWarning
            );
        }
        this.setState({ isFilingEmail: false });
    }

    private isFormValid(): boolean {
        return (
            !!this.state.selectedProject &&
            !!this.state.name &&
            !this.state.isFilingEmail &&
            !!this.state.selectedKeyword &&
            this.props.formValidationHelpers.areAssigneesValid(this.state.assignees, false)
        );
    }

    private getAssignees(): ContactRequest[] {
        return this.state.assignees.map((assignee) => {
            const nrn = (assignee as any).data?.nrn;
            if (nrn) {
                return { nrn };
            }
            return { email: assignee.text };
        });
    }

    private onProjectSelected(selectedItem?: ITag | null): ITag | null {
        this.setState({ selectedKeyword: null, assignees: [], keywords: [] });
        if (!selectedItem) {
            this.setState({ selectedProject: null });
            return null;
        }
        this.setState({ selectedProject: selectedItem });
        // currently ignoring this promise because it was causing a delay in updating the ui with user's selection. code executes as expected
        // tslint:disable-next-line
        this.getKeywords(selectedItem.key);
        return selectedItem;
    }

    private onKeywordSelectionChange(option: DetailedKeyword | null): void {
        this.setState({ selectedKeyword: option });
    }

    private onDescriptionChange(newValue?: string): void {
        this.setState({ description: newValue || "" });
    }

    private onOldEditorDescriptionChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void {
        this.setState({ description: newValue || "" });
    }

    private onNameChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        this.setState({ name: newValue || "" });
    }

    private onDateChange(date: Date | undefined): void {
        this.setState({ date: date });
    }

    private onAssigneeChange(assignees: IPersonaProps[]): void {
        this.setState({ assignees: assignees });
    }

    private onAttachmentUpdated(files: AttachmentItem[]): void {
        this.setState({
            attachments: files,
        });
        // prevent the page from scrolling up when removing items from the top of the list
        document.querySelector(".newforma-actionItemComponent")?.scrollTo(0, 1000);
    }

    fileUploadCallback(isInProgress: boolean, failedIds: string[]) {
        const failedAttachmentNames = !isInProgress ? this.getFailedAttachmentNames(failedIds) : [];
        this.setState({
            fileUploadIsInProgress: isInProgress,
            failedUploadAttachmentNames: failedAttachmentNames,
        });
    }

    private getFailedAttachmentNames(failedIds: string[]): string[] {
        if (!failedIds.length) {
            return [];
        }
        const failedAttachments = this.state.attachments.filter((attachment) => {
            return failedIds.includes(attachment.id);
        });
        return failedAttachments.map((attachment) => attachment.name);
    }

    private async refreshProjects(): Promise<void> {
        await this.props.invalidateCacheService.invalidateCache(ProjectsCacheKeys.actionItemsCacheName);
        return this.loadProjects();
    }

    render(): JSX.Element {
        return (
            <div className="newforma-actionItemComponent">
                <div className="newforma-aiForm">
                    <Label required={true}>{this.props.translate("ACTION_ITEM.NAME_LABEL") as string}</Label>
                    <TextField
                        className="newforma-aiSpacing"
                        id="ai-name"
                        value={this.state.name}
                        onChange={this.onNameChange.bind(this)}
                        disabled={this.state.isFilingEmail}
                    />
                    <SuggestedProjectPickerComponent
                        logger={this.props.logger}
                        className="newforma-aiSpacing"
                        onProjectSelected={this.onProjectSelected.bind(this)}
                        disabled={
                            this.state.isLoadingProjects ||
                            this.state.isFilingEmail ||
                            this.state.isLoadingKeywords ||
                            this.state.fileUploadIsInProgress
                        }
                        smartFilingManager={this.props.smartFilingManager}
                        projects={this.state.projects}
                        myProjects={this.state.projects}
                        isLoadingProjects={this.state.isLoadingProjects}
                        mailboxItem={this.props.mailboxItem}
                        onRefresh={this.refreshProjects.bind(this)}
                        theme={this.props.theme}
                    />
                    <KeywordsDropdown
                        className="newforma-formSpacing"
                        id="aiKeywordPicker"
                        options={this.state.keywords}
                        label={this.props.translate("ACTION_ITEM.KEYWORD_PICKER_LABEL") as string}
                        placeholder={this.props.translate("ACTION_ITEM.KEYWORD_PICKER_PLACEHOLDER") as string}
                        disabled={
                            !this.state.selectedProject ||
                            this.state.isLoadingKeywords ||
                            this.state.isFilingEmail ||
                            !this.state.keywords.length
                        }
                        isLoading={this.state.isLoadingKeywords}
                        isProjectSelected={!!this.state.selectedProject}
                        required={true}
                        onSelectionChange={this.onKeywordSelectionChange.bind(this)}
                        selectFirstOption={true}
                        theme={this.props.theme}
                        isActionItem={true}
                    />
                    <TranslatedDatePickerComponent
                        date={this.state.date}
                        label={this.props.translate("ACTION_ITEM.DUE_DATE_LABEL") as string}
                        disabled={this.state.isFilingEmail}
                        onDateChange={this.onDateChange.bind(this)}
                        className="newforma-aiSpacing"
                        required={false}
                        clearDateButtonVisibility={ElementVisibility.Visible}
                    />
                    <AssigneeComponent
                        className="newforma-aiSpacing actionItemAssignee"
                        assignees={this.state.assignees}
                        selectedProject={this.state.selectedProject}
                        disabled={this.state.isFilingEmail || !this.state.selectedProject}
                        currentUserEmail={this.props.officeWrapper.userProfileEmailAddress}
                        onError={() => null} // do nothing....will replace with inline validation ... this.props.onShowToast}
                        onAssigneeChanged={this.onAssigneeChange.bind(this)}
                        formValidationHelpers={this.props.formValidationHelpers}
                        logger={this.props.logger}
                        projectsService={this.props.projectsService}
                        label={this.props.translate("ACTION_ITEM.ASSIGNED_TO_LABEL") as string}
                        required={false}
                        showAssignToMe={true}
                        type="to-actionItem"
                    />
                    <div className="newforma-aiDescriptionContainer newforma-aiSpacing">
                        <Label>{this.props.translate("ACTION_ITEM.DESCRIPTION_LABEL") as string}</Label>
                        {this.props.isFileTransferAndEditorSupported ? (
                            <HTMLEditor
                                value={this.state.description}
                                onRemarksUpdate={this.onDescriptionChange.bind(this)}
                                configService={this.props.configService}
                                isFiling={this.state.isFilingEmail}
                            />
                        ) : (
                            <TextField
                                id="ai-description"
                                multiline
                                resizable={true}
                                value={this.state.description}
                                onChange={this.onOldEditorDescriptionChange.bind(this)}
                                disabled={this.state.isFilingEmail}
                                rows={5}
                            />
                        )}
                    </div>
                    <AttachmentsComponent
                        attachments={this.state.attachments}
                        disabled={this.state.isFilingEmail}
                        allowFileUploads={false}
                        onAttachmentsUpdated={this.onAttachmentUpdated.bind(this)}
                        logger={this.props.logger}
                        isFilePathInAttachmentsSupported={this.props.isFilePathInAttachmentsSupported}
                    />
                </div>
                {this.state.fileUploadIsInProgress ? (
                    <ProgressIndicator
                        label={this.props.translate("ACTION_ITEM.FILE_UPLOAD_PROGRESS_LABEL") as string}
                        className="newforma-aiProgressIndicator"
                        styles={{
                            itemName: ".ms-label",
                        }}
                    />
                ) : null}
                <div id="footer" key="footer" className="newforma-footer">
                    <DefaultButton
                        key="fileAsActionItem"
                        className="newforma-footerButton"
                        id="fileToProjectButton"
                        primary={true}
                        onClick={this.onFileActionItem.bind(this)}
                        text={(this.props.translate("ACTION_ITEM.CONFIRMATION_BUTTON") as string).toLocaleUpperCase()}
                        disabled={!this.isFormValid()}
                    />
                </div>
            </div>
        );
    }
}

export default withLocalize(ActionItemComponent);
