let DateHelper: DateHelpers;

/**
 * A list of days of the week
 */
export declare enum DaysOfWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
}
/**
 * Milliseconds in a day
 */
export const MS_IN_DAY = 24 * 60 * 60 * 1000;
export class DateHelpers {
    internalTodayDate: Date;

    constructor() {
        this.internalTodayDate = new Date();
    }
    /**
     * synchronized date/time . This time remains stable until refreshed by synchNow().
     */
    get TodayDate(): Date {
        return new Date(this.internalTodayDate);
    }
    setNow(date: Date) {
        this.internalTodayDate = new Date(date);
    }
    setToNoon(date: Date) {
        return new Date(date.getTime() + MS_IN_DAY / 2);
    }
    isValidDate(date: Date | undefined): boolean {
        if (!date) {
            return false;
        }

        const str = date.toString();

        return date instanceof Date && str !== "Invalid Date";
    }
    areThereBusinessDaysBetweenDateAndToday(date: Date | undefined): boolean {
        if (date) {
            const daysBetweenExpirationAndToday = DateHelper.calculateNumberOfDaysBetweenTwoDates(
                date,
                DateHelper.TodayDate
            );
            const areThereBusinessDays = !(
                daysBetweenExpirationAndToday &&
                ((daysBetweenExpirationAndToday === 3 && date.getDay() === 1) ||
                    (daysBetweenExpirationAndToday === 2 && date.getDay() === 0) ||
                    (daysBetweenExpirationAndToday === 1 && date.getDay() === 6))
            );
            return areThereBusinessDays;
        } else {
            return false;
        }
    }
    /**
     * stores the current date/time int the TodayDate property. This time remains stable until refreshed.
     */
    synchNow(): DateHelpers {
        this.internalTodayDate = new Date();
        return this;
    }
    /**
     * sets the time to the beginning of the day in local time ie. 12:00:00 am
     */
    adjustTimeToStartOfDay(theTime: number): number {
        return Math.floor(theTime / MS_IN_DAY) * MS_IN_DAY;
    }
    /**
     * truncate the date to the beginning of that date by zeroing out the time portion
     */
    adjustUTCDate(dateUTC: Date | undefined) {
        if (dateUTC) {
            const theDay = dateUTC.getDate();
            const theMonth = dateUTC.getMonth();
            const theYear = dateUTC.getFullYear();
            dateUTC.setFullYear(theYear, theMonth, theDay);
        }
    }
    /**
     * get the fraction 'time' part of the theDate
     */
    getPartOfDay(theDate: Date): number {
        const hours = theDate.getHours();
        const min = theDate.getMinutes();
        const secs = theDate.getSeconds();
        const ms = theDate.getMilliseconds();

        return (hours * 3600 + min * 60 + secs) * 1000 + ms;
    }
    /**
     * synchronize the fraction part of the theDate to the same fraction as the reference 'TodayDate'
     */
    adjustTime(theDate: Date): Date {
        const today = DateHelper.TodayDate;
        const modDate = theDate;
        // take the 'date' and strip off the fractional parts representing HMSMS
        modDate.setHours(0, 0, 0, 0);
        this.adjustUTCDate(modDate);

        // add back in the time component of the base date...
        // the result is a future date with the same 'time of day'
        // this allows us to keep dates in steps of 24 hrs.
        const adjDate = new Date(modDate.getTime() + this.getPartOfDay(today));

        return adjDate;
    }
    /**
     *  Adds a specified number of days to the date.  Days can be positive or negative.
     */
    offsetDate(theDate: Date, daysToAdd: number): Date {
        return new Date(theDate.getTime() + MS_IN_DAY * daysToAdd);
    }
    /**
     *  Convert date to the format YYYY-MM-DD using local time
     */
    yearMonthDay(date: Date): string {
        const d = new Date(date);
        const yyyy = d.getFullYear();
        const mm = d.getMonth() + 101;
        const dd = d.getDate() + 100; // pad with an extra digit, removed later

        return `${yyyy}-${mm.toString().slice(-2)}-${dd.toString().slice(-2)}`;
    }
    /**
     *  Returns true if date is before date2 (ignores time)
     */
    isDateBefore(date: Date, date2: Date): boolean {
        return this.yearMonthDay(date) < this.yearMonthDay(date2);
    }
    /**
     *  Returns true if date is after date2 (ignores time)
     */
    isDateAfter(date: Date, date2: Date): boolean {
        return this.yearMonthDay(date) > this.yearMonthDay(date2);
    }
    /**
     *  Returns true if the dates are on the same day
     */
    isDateEqual(date: Date, date2: Date): boolean {
        return !this.isDateBefore(date, date2) && !this.isDateAfter(date, date2);
    }
    /**
     *  Determines the days of difference between two dates, skipping weekends if business day traversal
     */
    subDate(startDate: Date | undefined, endDate: Date | undefined, BusinessDaysOnly: boolean = false): number {
        let theDays = 0;
        if (startDate && endDate) {
            for (const date = new Date(startDate); this.isDateBefore(date, endDate); ) {
                const shiftedDay = (date.getDay() + 6) % 7;

                if (!BusinessDaysOnly || shiftedDay < 5) {
                    theDays++;
                }
                date.setDate(date.getDate() + 1);
            }
        }
        return theDays;
    }
    /**
     * Adds a specified number of days ( business days or standard days) referenced from the input date
     * and calculates the ending date
     */
    addDays(theDate: Date, daysToAdd: number, BusinessDaysOnly: boolean = false): Date {
        let returnedDate = new Date(theDate);
        if (!daysToAdd) {
            return returnedDate;
        }
        try {
            // strangeness from uc
            if (!this.isValidDate(returnedDate)) {
                return theDate;
            }
            if (BusinessDaysOnly) {
                const direction = daysToAdd > 0 ? 1 : -1;
                let daysRemaining = Math.abs(daysToAdd);

                while (daysRemaining > 0) {
                    returnedDate = this.offsetDate(returnedDate, direction);
                    const shiftedDay = (returnedDate.getDay() + 6) % 7;
                    if (shiftedDay < 5) {
                        daysRemaining--;
                    }
                }
            } else {
                returnedDate = this.offsetDate(returnedDate, daysToAdd);
            }
        } catch {}
        return returnedDate;
    }
    /**
     *  Tests if two dates are considered the same day  by ignoring the time (hmsms) part of the day.
     */
    isSameDay(date1: Date, date2: Date): boolean {
        const result =
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();

        return result;
    }

    addBusinessDays(originalDate: Date, daysToAdd: number) {
        const Sunday = 0;
        const Saturday = 6;
        let daysRemaining = daysToAdd;

        const newDate = new Date(originalDate);

        while (daysRemaining > 0) {
            newDate.setDate(newDate.getDate() + 1);
            if (newDate.getDay() !== Sunday && newDate.getDay() !== Saturday) {
                daysRemaining--;
            }
        }

        return newDate;
    }
    addBusinessDaysBeforeExpiration(expireDate: Date, daysToAdd: number) {
        const Sunday = 0;
        const Saturday = 6;
        let daysRemaining = daysToAdd;

        const newDate = new Date(expireDate);

        while (daysRemaining > 0) {
            newDate.setDate(newDate.getDate() - 1);
            if (newDate.getDay() !== Sunday && newDate.getDay() !== Saturday) {
                daysRemaining--;
            }
        }

        return newDate;
    }

    getDateWithOffset(date: Date, offsetInDays: number): Date {
        const newDate = new Date(date);

        newDate.setDate(newDate.getDate() + offsetInDays);

        return newDate;
    }

    getNewDateWithOffset(offsetInSeconds: number): Date {
        const newDate = new Date();
        newDate.setSeconds(newDate.getSeconds() + offsetInSeconds);
        return newDate;
    }

    getNewDateAtStartOfDay(date: Date): Date {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    }

    isDateInThePast(date: Date | null | undefined): boolean {
        const today = new Date();
        const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        return date && date < startOfToday ? true : false;
    }

    calculateNumberOfDaysBetweenTwoDates(
        firstDate: Date | undefined,
        secondDate: Date | undefined
    ): number | undefined {
        if (firstDate instanceof Date && secondDate instanceof Date) {
            const differenceInMilSeconds = firstDate.getTime() - secondDate.getTime();
            const differenceInDays = differenceInMilSeconds / (1000 * 3600 * 24);
            return differenceInDays;
        } else {
            return undefined;
        }
    }

    timeSmall(theTime:Date = new Date ):string {
        
        const hours = theTime.getHours();
        const minutes =  String(theTime.getMinutes()).padStart(2, '0'); 
        const seconds = String(theTime.getSeconds()).padStart(2, '0'); 
        
        return `${hours % 12 || 12}:${minutes}:${seconds} ${hours >= 12 ? 'PM' : 'AM'}`;
    }
}

DateHelper = new DateHelpers();

export { DateHelper };
