import { ConfigurationsResponse } from "../../models/ConfigurationsResponse";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { KeyValuePair } from "../../models/KeyValuePair";
import { NewformaApiClient } from "./NewformaApiClient";
import { Logger } from "../Logger";
import { UnauthenticatedNewformaApiClient } from "../UnauthenticatedNewformaApiClient";
import { TeachingBubblesTypes } from "../../models/TeachingBubblesTypes";

export class ConfigurationsApiService {
    private readonly configurationsUri = "/v1/configurations";

    private configurations: KeyValuePair[] = [];
    private teachingBubbles: string[] = Object.keys(TeachingBubblesTypes).filter((item) => isNaN(Number(item)));

    constructor(
        private newformaApiClient: NewformaApiClient,
        private unauthenticatedClient: UnauthenticatedNewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private logger: Logger
    ) {}

    async getUnauthenticatedConfigurations(): Promise<void> {
        this.logger.info("Retrieving unauthenticated configurations");
        const configurationsResponse = await this.unauthenticatedClient.getConfigurations();
        this.configurations = configurationsResponse.configurations;
    }

    async getAuthenticatedConfigurations(): Promise<void> {
        this.logger.info("Retrieving authenticated configurations");
        const configurationsResponse = await this.makeGetAuthenticatedConfigurationsRequest();
        configurationsResponse.configurations.concat(configurationsResponse.license.parameters).forEach((pair) => {
            const existingItem = this.configurations.find((x) => x.key === pair.key);
            if (existingItem) {
                existingItem.value = pair.value;
            } else {
                this.configurations.push(pair);
            }
        });
        this.teachingBubbles = configurationsResponse.teachingBubbles;
    }

    async updateCompletedTeachingBubblesSteps(teachingBubblesToUpdate: string[]): Promise<void> {
        this.logger.info("Updating teaching bubbles progress");
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}${this.configurationsUri}/me/teachingBubbles`;
        const payload = JSON.stringify({ bubbleNames: teachingBubblesToUpdate });
        const options = {
            url: url,
            method: "PATCH",
            body: payload,
            headers: { "x-newforma-agent": this.newformaApiClient.getNewformaAgent() },
        };

        return this.newformaApiClient.makeRequest(options, async (signedOptions) =>
            this.requestWrapper.patch(url, undefined, signedOptions.headers, payload)
        );
    }

    updateCompletedTeachingBubblesCollection(teachingBubble: TeachingBubblesTypes): void {
        this.teachingBubbles.push(teachingBubble);
    }

    private async makeGetAuthenticatedConfigurationsRequest(): Promise<ConfigurationsResponse> {
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}${this.configurationsUri}/me`;
        const options = {
            url: url,
            method: "GET",
            headers: { "x-newforma-agent": this.newformaApiClient.getNewformaAgent() },
        };

        return this.newformaApiClient.makeRequest(options, async (signedOptions) =>
            this.requestWrapper.get(url, undefined, signedOptions.headers, undefined)
        );
    }

    isActionItemsSupported(): boolean {
        return this.isFeatureSupported("hasActionItemsSupport");
    }

    isSubmittalsSupported(): boolean {
        return this.isFeatureSupported("hasSubmittalsSupport");
    }

    isRfiSupported(): boolean {
        return this.isFeatureSupported("hasRfisSupport");
    }

    isRespondCloseSupported(): boolean {
        return true; // TODO
    }

    isEulaSupported(): boolean {
        return this.isFeatureSupported("eula-is-enabled");
    }

    isFileTransferAndEditorSupported(): boolean {
        return this.isFeatureSupported("file-transfer-add-in");
    }

    isFileTransferAndEditorSupportedBasedOnNLVersion(): boolean {
        return this.isFeatureSupported("hasFileTransfersSupport");
    }

    isFilePathSupportedInAttachments(): boolean {
        return this.isFeatureSupported("canAttachFilesInFolders");
    }

    isForwardSubmittalSupported(): boolean {
        return this.isFeatureSupported("outlook-add-in-forward-a-submittal-for-review");
    }

    isForwardRfiSupported(): boolean {
        return this.isFeatureSupported("outlook-add-in-forward-an-rfi-for-review");
    }

    isForwardSubmittalAndRfiSupportedBasedOnNLVersion(): boolean {
        return this.isFeatureSupported("hasForwardSubmittalAndRfiSupport");
    }

    shouldTeachingBubbleBeShown(bubbleName: TeachingBubblesTypes): boolean {
        return !this.teachingBubbles.some((teachingBubble) => teachingBubble === bubbleName);
    }

    private isFeatureSupported(key: string): boolean {
        const result = this.configurations.find((x) => x.key === key);
        if (!result) {
            return false;
        }
        return result.value;
    }
}
